import React from "react";
import {HistoryItem} from "../components/HistoryItem";
import {UserId} from "../Sync";
import {GameEvent} from "../StateMachine";
import {IonCard, IonCardContent, IonContent, IonPage} from "@ionic/react";
import {renderHeader} from "../components/Utils";

type HistoryListProps = {
    events: Array<GameEvent>,
    onDelete(gameEvent: GameEvent, state: boolean): void,
    users: Map<string, UserId>
    gameMaster: boolean
    points?: number
}

export class HistoryList extends React.PureComponent<HistoryListProps, any> {

    render() {
        return <IonPage>
            {renderHeader("Event history", true, this.props.points)}
            <IonContent fullscreen >
                {renderHeader("Event history", false, this.props.points)}
            <IonCard>
                <IonCardContent>
                    As game master, you can remove events, e.g. if there was an error by the player.
                    The game will be recalculated then. Deleted events might cause other events to be broken. For
                    example, if you remove a gear item being added, all events related to that gear item
                    will be affected.
                </IonCardContent>
            </IonCard>
            {
                Array.from(this.props.events)
                    .reverse()
                    .map(gameEvent =>
                        <HistoryItem key={gameEvent.eventId} gameEvent={gameEvent}
                                     onDelete={this.props.onDelete} users={this.props.users}
                                     gameMaster={this.props.gameMaster}
                        />
                    )
            }
            </IonContent>
        </IonPage>;
    }
}