import React from "react";
import {GearListItem} from "../components/GearListItem";
import {gearSorter, renderHeader} from "../components/Utils";
import {
    EquipGear,
    Gear,
    UnequipGear
} from "../StateMachine";
import {
    IonButton,
    IonContent,
    IonFab,
    IonFabButton,
    IonIcon,
    IonList,
    IonListHeader,
    IonPage
} from "@ionic/react";
import {FormulaObject} from "../Math";
import {add} from "ionicons/icons";

type GearListProps = {
    gameMaster: boolean,
    pushEvent(event: EquipGear | UnequipGear): void
    gear: Map<string, Gear>,
    desiredGearCount?: FormulaObject,
    points?: number,
    dailyDecrease: number,
    initialized: boolean
}

export class GearList extends React.PureComponent<GearListProps, any> {

    onChange = (gear: Gear, state: boolean) => {
        if (state) {
            this.props.pushEvent({
                type: "equipGear",
                gear: gear
            });
        } else {
            this.props.pushEvent({
                type: "unequipGear",
                gear: gear
            });
        }
    }

    render() {

        return <IonPage>
            {renderHeader("Gear", true, this.props.points)}

            <IonContent fullscreen >
                {renderHeader("Gear", false, this.props.points)}
                {this.props.initialized ||
                <IonButton routerLink={this.props.gameMaster?"/history/setPoints":undefined} expand={"block"} color={"danger"}>
                    Game not started
                </IonButton>
                }
                <IonListHeader>Currently worn gear</IonListHeader>
                <IonList>
                    {
                        Array.from(this.props.gear.values())
                            .filter(gear => gear.equipped && !gear.suggested)
                            .sort(gearSorter)
                            .map((gear) =>
                                <GearListItem key={gear.id} gear={gear} showCheckBox={this.props.initialized}
                                              desiredGearCount={this.props.desiredGearCount}
                                              onChange={this.onChange} showLiveStats={true}/>)
                    }
                </IonList>
                <IonListHeader>Available gear</IonListHeader>
                <IonList>
                    {
                        Array.from(this.props.gear.values())
                            .filter(gear => !gear.equipped && !gear.suggested)
                            .sort(gearSorter)
                            .map((gear) =>
                                <GearListItem key={gear.id} gear={gear} showCheckBox={this.props.initialized}
                                              desiredGearCount={this.props.desiredGearCount}
                                              onChange={this.onChange} showLiveStats={false}/>)
                    }
                </IonList>
                <IonListHeader>Suggested gear</IonListHeader>
                <IonList>
                    {
                        Array.from(this.props.gear.values())
                            .filter(gear => gear.suggested)
                            .sort(gearSorter)
                            .map((gear) =>
                                <GearListItem key={gear.id} gear={gear} showCheckBox={false}
                                              desiredGearCount={this.props.desiredGearCount}
                                              onChange={this.onChange} showLiveStats={false}/>)
                    }
                </IonList>
                <IonListHeader>
                    <small>Swipe gear to the right to equip/unequip.</small>
                </IonListHeader>
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton routerLink={"/gear/add"}>
                        <IonIcon icon={add} />
                    </IonFabButton>
                </IonFab>
            </IonContent>

        </IonPage>;
    }
}