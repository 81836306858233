import {
    IonButton, IonCard, IonCardContent,
    IonContent,
    IonPage
} from '@ionic/react';
import {renderHeader} from "../components/Utils";
import React from "react";

const Welcome: React.FC = () => {

    return (
        <IonPage>
            {renderHeader("Welcome", true)}

            <IonContent fullscreen >
                {renderHeader("Welcome", false)}
                <section className="ion-padding">
                    <h1>Permanently Kinky</h1>
                    <p><small>A game about wearing kinky gear and collecting points.</small></p>
                    <IonCard>
                        <IonCardContent>
                            <h2>How to play:</h2>
                            <p>
                                This game is played by a game master and a player. Each game is individually configured
                                by the game master, who has full control over the game.
                                They can add gear, that the player has to wear over a certain amount
                                of time, and tasks, that the player can complete at any time.<br/>
                                Wearing gear (e.g. a collar) adds points to the player score.
                                The more gear is worn at the same time, the
                                more points can be made. There is a configurable limit.<br/>
                                Tasks can be completed to gain or loose points. They can have repetition limits or they
                                might only be allowed, if the player score is below or above a limit.<br/>
                                The player score is constantly reduced to incentivize the player to wear gear again.<br/>
                                There is no win condition build into this game, but a task can be used to emulate one.
                            </p>
                        </IonCardContent>
                    </IonCard>
                    <IonButton routerLink={"/game"} expand="block">Start game</IonButton>
                    <IonButton routerLink={"/privacy"} expand="block">Privacy Policy</IonButton>
                    <p><small><b>Disclaimer:</b> This software is provided by the copyright holders and contributors "as is" and any
                    express or implied warranties, including, but not limited to, the implied warranties of
                    merchantability and fitness for a particular purpose are disclaimed. In no event shall the
                    copyright holder or contributors be liable for any direct, indirect, incidental, special,
                    exemplary, or consequential damages (including, but not limited to, procurement of
                    substitute goods or services; loss of use, data, or profits; or business interruption)
                    however caused and on any theory of liability, whether in contract, strict liability, or
                    tort (including negligence or otherwise) arising in any way out of the use of this
                    software, even if advised of the possibility of such damage.</small></p>
                    <p><small>Logo by Lorc on <a href="https://game-icons.net">game-icons.net</a></small></p>
                </section>
            </IonContent>
        </IonPage>
    );
};

export default Welcome;