import React from "react";
import {CompleteTask, Task} from "../StateMachine";
import {IonBadge, IonButton, IonCard, IonCardContent} from "@ionic/react";


type TaskListItemProps = {
    task: Task,
    canComplete: boolean,
    pushEvent(event: CompleteTask): void,
}

export class TaskListItem extends React.PureComponent<TaskListItemProps, any> {
    completeTask = () => {
        this.props.pushEvent({
            type: "completeTask",
            task: this.props.task
        });
    }

    render() {
        return <IonCard key={this.props.task.id}>
            <IonCardContent>
                <IonButton expand={"full"} fill={"clear"} routerLink={"/tasks/edit/"+this.props.task.id}>
                    {this.props.task.title}

                </IonButton>
                <div className="content">
                    <IonBadge style={{float: "right"}}>{this.props.task.points} Points</IonBadge>
                    {this.props.task.description &&
                        <p>
                            {this.props.task.description}
                        </p>
                    }
                    <p style={{clear: "both"}}>
                        Requirement: {this.props.task.requirement === "no" ? "none" :
                        (this.props.task.requirement === "lt" ? "<" :
                            this.props.task.requirement === "le" ? "<=" :
                                this.props.task.requirement === "gt" ? ">" : ">=") +
                        " " + this.props.task.requirementPoints + " Points"
                    }
                        <span style={{float: "right"}}>{this.props.task.unlimitedRepeats ?
                            "Repeatable" : this.props.task.availableRepeats + " times"}</span>
                    </p>
                    {this.props.canComplete && this.props.task.active &&
                        <p>
                            <IonButton expand="block" onClick={this.completeTask}>Complete</IonButton>
                        </p>
                    }
                </div>
            </IonCardContent>
        </IonCard>;
    }
}