import React from "react";
import {UserId} from "../Sync";
import {GameEvent} from "../StateMachine";
import {Hashicon} from "./Hashicon";
import {formatTime, renderDifficulty} from "./Utils";
import {IonIcon, IonItem, IonItemDivider, IonLabel} from "@ionic/react";
import {reloadCircle, trash} from "ionicons/icons";

type HistoryItemProps = {
    gameEvent: GameEvent
    onDelete(event: GameEvent, state: boolean): void
    users: Map<string, UserId>
    gameMaster: boolean
}

export class HistoryItem extends React.PureComponent<HistoryItemProps, any> {
    renderEvent(gameEvent: GameEvent, error: JSX.Element | undefined): JSX.Element {
        switch (gameEvent.type) {
            case "setPoints":
                return <div className="content">
                    {error !== undefined && error}
                    {gameEvent.points} Points
                </div>
            case "dailyDecreaseChange":
                return <div className="content">
                    {error !== undefined && error}
                    {gameEvent.dailyDecrease} Points
                </div>
            case "desiredGearCountChange":
                return <div className="content">
                    {error !== undefined && error}
                    {gameEvent.count.desiredValue} Items, {gameEvent.count.baseValue * 100}%
                    Base, {gameEvent.count.difficulty * 100}% Difficulty
                </div>
            case "addOrModifyGear":
            case "suggestGear":
            case "equipGear":
            case "removeGear":
                return <div className="content">
                    {error !== undefined && error}
                    {gameEvent.gear.title} ({gameEvent.gear.id})<br/>
                    {renderDifficulty(gameEvent.gear.desiredDuration.difficulty)},
                    {gameEvent.gear.desiredDuration.points} Points / {gameEvent.gear.desiredDuration.duration} Hours
                </div>
            case "unequipGear":
                return <div className="content">
                    {error !== undefined && error}
                    {gameEvent.gear.title} ({gameEvent.gear.id})<br/>
                    {renderDifficulty(gameEvent.gear.desiredDuration.difficulty)},
                    {gameEvent.gear.desiredDuration.points} Points / {gameEvent.gear.desiredDuration.duration} Hours
                    {gameEvent.validation?.valid && gameEvent.validation.pointsResult !== undefined &&
                        <span> ={">"} { Math.round(gameEvent.validation.pointsResult.points * 100) / 100 } Points
                        after { formatTime(gameEvent.validation.pointsResult.hours * 60) }</span>}
                </div>
            case "addOrModifyTask":
            case "suggestTask":
            case "completeTask":
            case "removeTask":
                return <div className="content">
                    {error !== undefined && error}
                    {gameEvent.task.title} ({gameEvent.task.id})<br/>
                    {gameEvent.task.description}<br/>
                    Active: {gameEvent.task.active ? "yes" : "no"}, Points: {gameEvent.task.points}<br/>
                    Requirement: {gameEvent.task.requirement} {gameEvent.task.requirementPoints}<br/>
                    Repeats: {gameEvent.task.unlimitedRepeats ? "unlimited" : gameEvent.task.availableRepeats}
                </div>
            default:
                return <div className="content">
                    {error !== undefined && error}
                    Unknown Event Type
                </div>
        }
    }

    renderIcon(gameEvent: GameEvent): JSX.Element{
        switch (gameEvent.type) {
            case "setPoints":
            case "dailyDecreaseChange":
            case "desiredGearCountChange":
                return <Hashicon value={gameEvent.type} size={32}/>
            case "addOrModifyGear":
            case "suggestGear":
            case "equipGear":
            case "unequipGear":
            case "removeGear":
                return <Hashicon value={gameEvent.gear.id} size={32}/>
            case "addOrModifyTask":
            case "suggestTask":
            case "completeTask":
            case "removeTask":
                return <Hashicon value={gameEvent.task.id} size={32}/>
        }
    }

    onButtonClick = () => {
        this.props.onDelete(this.props.gameEvent, this.props.gameEvent.removed !== true)
    }

    render() {
        let title = {
            "setPoints": "Point score set",
            "desiredGearCountChange": "Desired gear count changed",
            "dailyDecreaseChange": "Daily Decrease changed",
            "addOrModifyGear": "Gear added or modified",
            "suggestGear": "Gear suggested",
            "addOrModifyTask": "Task added or modified",
            "suggestTask": "Task suggested",
            "equipGear": "Gear equipped",
            "unequipGear": "Gear unequipped",
            "completeTask": "Task completed",
            "removeGear": "Gear removed",
            "removeTask": "Task removed"
        }
        let gameEvent = this.props.gameEvent;
        let error: JSX.Element | undefined = this.props.gameEvent.validation !== undefined &&
        !this.props.gameEvent.validation.valid ?
            <span><b>Error: {this.props.gameEvent.validation.message}</b><br/></span> : undefined;

        let user = gameEvent.userId === undefined ? undefined : this.props.users.get(gameEvent.userId);
        return <div>
            <IonItemDivider>
                <IonLabel>
                    {gameEvent.timestamp !== undefined && new Date(gameEvent.timestamp).toLocaleString()}
                    {user !== undefined &&
                        <span title={gameEvent.userId}> by {user.name} {user.gameMaster && <b>(GM)</b>}</span>
                    }
                </IonLabel>
            </IonItemDivider>
            <IonItem button onClick={this.onButtonClick} color={this.props.gameEvent.validation !== undefined &&
            !this.props.gameEvent.validation.valid ? "danger" : (this.props.gameEvent.removed === true ? "dark" : undefined)
            }>
                <div slot="start">
                { this.renderIcon(gameEvent) }
                </div>

                <IonLabel>
                    {title[gameEvent.type]} {this.props.gameEvent.removed && "(Removed)"}
                    {
                        this.renderEvent(gameEvent, error)
                    }
                </IonLabel>
                {this.props.gameMaster &&
                    <IonIcon slot="end" icon={this.props.gameEvent.removed ? reloadCircle : trash}
                                 size={"24px"}/>
                }
            </IonItem></div>;
    }
}