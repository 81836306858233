interface DataPoints {
    labels: Array<number>,
    data: Array<number>
}

export interface Formula {
    readonly desiredValue: number,
    readonly baseValue: number,
    readonly maxValue: number,
    readonly difficulty: number,
}

export interface Graphable {
    dataPoints(steps: number): DataPoints
}

export class FormulaObject implements Formula, Graphable {

    private constructor(public readonly desiredValue: number,
                        public readonly baseValue: number,
                        public readonly maxValue: number,
                        public readonly difficulty: number,
                        public readonly difficultyExponent: number) {
    }

    static create(json?: Formula): FormulaObject {
        if (json === undefined) {
            return new FormulaObject(1, 0, 1, 0.5, 2);
        } else {
            this.desiredValueCheck(json.desiredValue);
            this.baseValueCheck(json.baseValue);
            this.maxValueCheck(json.maxValue);
            this.difficultyCheck(json.difficulty);
            return new FormulaObject(json.desiredValue, json.baseValue, json.maxValue, json.difficulty, FormulaObject.calculateDifficultyExponent(json.difficulty))
        }
    }

    private static desiredValueCheck(value:number): void {
        if (!isFinite(value) || value <= 0) {
            throw new Error("Desired value must be positive");
        }
    }

    withDesiredValue(value: number): FormulaObject {
        FormulaObject.desiredValueCheck(value);
        return new FormulaObject(value, this.baseValue, this.maxValue, this.difficulty, this.difficultyExponent);
    }

    private static baseValueCheck(value: number): void {
        if (!isFinite(value) || value < 0 || value >= 1) {
            throw new Error("Base percentage must be between 0 (inclusive) and 1 (exclusive)");
        }
    }

    withBaseValue(value: number): FormulaObject {
        FormulaObject.baseValueCheck(value);
        return new FormulaObject(this.desiredValue, value, this.maxValue, this.difficulty, this.difficultyExponent);
    }

    private static maxValueCheck(value: number): void {
        if (!isFinite(value) || value <= 0) {
            throw new Error("Max value must be positive");
        }
    }

    withMaxValue(value: number): FormulaObject {
        FormulaObject.maxValueCheck(value);
        return new FormulaObject(this.desiredValue, this.baseValue, value, this.difficulty, this.difficultyExponent);
    }

    private static difficultyCheck(value: number): void {
        if (!isFinite(value) || value < 0 || value >= 0.991) {
            throw new Error("Base percentage must be between 0 (inclusive) and 1 (exclusive)");
        }
    }

    withDifficulty(value: number): FormulaObject {
        FormulaObject.difficultyCheck(value);
        let difficultyExponent = FormulaObject.calculateDifficultyExponent(value);
        return new FormulaObject(this.desiredValue, this.baseValue, this.maxValue, value, difficultyExponent);
    }

    static calculateDifficultyExponent(value: number): number {
        let d: number = 200;
        let x: number = d / 2;
        if (value === 0) {
            return 0;
        }
        for (let i = 0; i < 100; i++) {
            let y = (d * (1 - value) + 1) * Math.pow(value, d);
            if (y < 0.5) {
                d = d - x;
            } else {
                if (y === 0.5000000000000001) {
                    break;
                }
                d = d + x;
            }
            x = x / 2;
        }
        return d;
    }

    calculate(value: number): number {
        if (this.difficultyExponent === 0) {
            return value > this.desiredValue ? 0 : this.maxValue;
        }
        const scaledValue: number = value / this.desiredValue;
        return Math.max(0, (this.maxValue - this.baseValue) *
            (this.difficultyExponent * (1 - scaledValue) + 1) * Math.pow(scaledValue, this.difficultyExponent) + this.baseValue);

    }

    zeroPoint(): number {
        if (this.difficultyExponent === 0) {
            return this.desiredValue;
        }
        if (this.baseValue === 0) {
            return this.desiredValue * ((this.difficultyExponent + 1) / this.difficultyExponent);
        }
        return this.calculateZeroPoint();
    }

    calculateZeroPoint(): number {
        let value = this.desiredValue * ((this.difficultyExponent + 1) / this.difficultyExponent);
        let d: number = 1000;
        let x: number = d / 2;
        for (let i = 0; i < 100; i++) {
            let y = this.calculate(value + d);
            if (y <= 0) {
                d = d - x;
            } else {
                if (y < 0.000000001) {
                    break;
                }
                d = d + x;
            }
            x = x / 2;
        }
        return value + d;
    }

    dataPoints(steps: number): DataPoints {
        const labels = [];
        const data = [];
        for (let i = 0; i <= steps; i++) {
            const x = i * this.zeroPoint() / steps;
            const y = this.calculate(x);
            labels.push(x);
            data.push(y);
        }
        return {labels: labels, data: data};
    }
}

export interface DurationFormula {
    /* > 0 */
    readonly duration: number,
    /* > 0 */
    readonly points: number,
    /* > 1 */
    readonly difficulty: number,
    /* 0 - 1 */
    readonly increaseAfterSuccess: number
}

export class DurationFormulaCalc implements DurationFormula, Graphable {

    private constructor(public readonly duration: number,
                        public readonly points: number,
                        public readonly difficulty: number,
                        public readonly increaseAfterSuccess: number,
                        public readonly exponent: number,
                        public readonly mulitplier: number) {
    }

    static create(json?: DurationFormula): DurationFormulaCalc {
        if (json === undefined) {
            return new DurationFormulaCalc(24, 10, 1, 1, 2, 1);
        } else {
            this.durationCheck(json.duration);
            this.pointsCheck(json.points);
            this.difficultyCheck(json.difficulty);
            let exponent = Math.pow(2, json.difficulty);
            this.increaseAfterSuccessCheck(json.increaseAfterSuccess, exponent);
            return new DurationFormulaCalc(json.duration, json.points, json.difficulty, json.increaseAfterSuccess,
                exponent,
                DurationFormulaCalc.calculateMultiplier(exponent, json.increaseAfterSuccess, 0.000000000000001))
        }
    }

    private static durationCheck(value:number): void {
        if (!isFinite(value) || value <= 0) {
            throw new Error("Duration must be positive");
        }
    }

    withDuration(value: number): DurationFormula {
        return DurationFormulaCalc.create({...this, duration: value});
    }

    private static pointsCheck(value:number): void {
        if (!isFinite(value) || value <= 0) {
            throw new Error("Points must be positive");
        }
    }

    withPoints(value: number): DurationFormula {
        return DurationFormulaCalc.create({...this, points: value});
    }

    private static difficultyCheck(value: number): void {
        if (!isFinite(value) || value < 1) {
            throw new Error("Difficulty must be higher than 1");
        }
    }

    withDifficulty(value: number): DurationFormula {
        return DurationFormulaCalc.create({...this, difficulty: value});
    }

    private static increaseAfterSuccessCheck(value: number, exponent: number): void {
        if (!isFinite(value) || value < 0  || value > 3 / 4 * exponent) {
            throw new Error("Increase after success must be positive and less or equal to " + (3 / 4 * exponent * 100) + "%");
        }
    }

    withIncreaseAfterSuccessCheck(value: number): DurationFormula {
        return DurationFormulaCalc.create({...this, increaseAfterSuccess: value});
    }

    static calculateMultiplier(exponent: number, increase: number, delta: number): number {
        let t = Math.pow(Math.pow(16,  - 1 / exponent), increase - exponent);
        /* t should be between 2 (inclusive) and 16 (inclusive) */
        /* y will be between 1 and 17.18 */
        let d = 16;
        let x = d / 2;

        for (let i = 0; i < 100; i++) {
            let y = d * Math.pow(t, 1 / d) - d;
            if (y > 3 - delta && y < 3 + delta) {
                break;
            }
            if (y < 3) {
                d = d - x;
            } else {
                d = d + x;
            }
            x = x / 2;
        }
        return d;
    }

    calculate(value: number): number {
        const scaledValue: number = value / this.duration;
        if (scaledValue > 1) {
            return this.points + (scaledValue - 1) * this.points * this.increaseAfterSuccess;
        }
        return Math.max(0, this.points *
            (this.mulitplier * Math.pow(scaledValue ,
                (this.increaseAfterSuccess + this.mulitplier * this.exponent - this.exponent)
                    / this.mulitplier)
                - (this.mulitplier - 1) * Math.pow(scaledValue , this.exponent)));
    }

    dataPoints(steps: number): DataPoints {
        const labels = [];
        const data = [];
        for (let i = 0; i <= steps; i++) {
            const x = i * this.duration * 1.2 / steps;
            const y = this.calculate(x);
            labels.push(x);
            data.push(y);
        }
        return {labels: labels, data: data};
    }
}
