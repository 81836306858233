import React from "react";
import {Gear, Task} from "../StateMachine";
import {
    IonButtons,
    IonHeader,
    IonMenuButton,
    IonTitle,
    IonToolbar,
    IonBadge,
    IonButton, IonIcon
} from "@ionic/react";
import {close} from "ionicons/icons";

export function renderHeader(title: String, collapsed: boolean, points?: number, onClose?: () => void): JSX.Element {
    return collapsed ?
        <IonHeader collapse="condense">
            <IonToolbar>
                <IonTitle size="large">{title}
                    {points !== undefined && <IonBadge style={{float: "right"}}>
                        {Math.round(points * 100) / 100} Points
                    </IonBadge>}
                </IonTitle>
                {onClose !== undefined &&
                    <IonButtons slot="end">
                        <IonButton onClick={() => onClose()}>
                            <IonIcon icon={close}/>
                        </IonButton>
                    </IonButtons>
                }
            </IonToolbar>
        </IonHeader> :
        <IonHeader>
            <IonToolbar>
                {onClose === undefined &&
                    <IonButtons slot="start">
                        <IonMenuButton/>
                    </IonButtons>
                }
                <IonTitle>{title}
                    {points !== undefined && <IonBadge style={{float: "right"}}>
                        {Math.round(points * 100) / 100} Points
                    </IonBadge>}
                </IonTitle>
                {onClose !== undefined &&
                    <IonButtons slot="end">
                        <IonButton onClick={() => onClose()}>
                            <IonIcon icon={close}/>
                        </IonButton>
                    </IonButtons>
                }
            </IonToolbar>
        </IonHeader>
}

export function gearSorter(a: Gear, b: Gear): number {
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1
    }
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1
    }
    if (a.desiredDuration.duration < b.desiredDuration.duration) {
        return -1
    }
    if (a.desiredDuration.duration > b.desiredDuration.duration) {
        return 1
    }
    if (a.desiredDuration.difficulty < b.desiredDuration.difficulty) {
        return -1
    }
    if (a.desiredDuration.difficulty > b.desiredDuration.difficulty) {
        return 1
    }
    if (a.desiredDuration.increaseAfterSuccess < b.desiredDuration.increaseAfterSuccess) {
        return -1
    }
    if (a.desiredDuration.increaseAfterSuccess > b.desiredDuration.increaseAfterSuccess) {
        return 1
    }
    return 0
}

export function taskSorter(a: Task, b: Task) {
    if (!a.active && b.active) {
        return -1
    }
    if (a.active && !b.active) {
        return 1
    }
    if (a.title.toLowerCase() < b.title.toLowerCase()) {
        return -1
    }
    if (a.title.toLowerCase() > b.title.toLowerCase()) {
        return 1
    }
    return 0
}

export function parseIntOrDefault(value: string | null | undefined, defaultValue: number): number {
    if (value === null || value === undefined) {
        return defaultValue;
    }
    let check = parseInt(value);
    if (!isFinite(check)) {
        return defaultValue;
    }
    return check;
}

export function renderDifficulty(value: number): string {
    switch (value) {
        case 1:
            return "easy";
        case 2:
            return "normal";
        case 3:
            return "hard";
        case 4:
            return "very hard";
        case 5:
            return "expert";
        default:
            return value + "";
    }
}

export function formatTime(diffMinutes: number): string {
    let minutes = Math.round(diffMinutes % 60).toString()
    minutes = minutes.length === 1 ? "0" + minutes : minutes;
    return Math.floor(diffMinutes / 60) + ":" + minutes;
}
