import React from "react";
import {UserId} from "../Sync";
import {
    AddOrModifyGear,
    AddOrModifyTask,
    CompleteTask, DailyDecreaseChange, DesiredGearCountChange,
    EquipGear,
    GameEvent,
    Gear, RemoveGear, RemoveTask, SetPoints, SuggestGear,
    SuggestTask,
    Task,
    UnequipGear
} from "../StateMachine";
import {
    IonTabs,
    IonTabBar,
    IonTabButton,
    IonLabel,
    IonIcon,
    IonRouterOutlet,
    IonContent,
} from "@ionic/react";
import {calendar, clipboard, paw} from "ionicons/icons";
import {GearList} from "./GearList";
import {Route} from "react-router-dom";
import {TaskList} from "./TaskList";
import {HistoryList} from "./HistoryList";
import {FormulaObject} from "../Math";
import {GearDetails} from "./GearDetails";
import {DesiredGearCount} from "./DesiredGearCount";
import {DailyDecrease} from "./DailyDecrease";
import {SetPointsPage} from "./SetPointsPage";
import {TaskDetails} from "./TaskDetails";

type MainPageProps = {
    points: number,
    gameMaster: boolean,
    pushEvent(event: EquipGear | UnequipGear | CompleteTask | AddOrModifyGear | SuggestGear |
        RemoveGear | AddOrModifyTask | SuggestTask | RemoveTask | DesiredGearCountChange | DailyDecreaseChange |
        SetPoints): void
    onDelete(event: GameEvent, state: boolean): void
    openMenu?(): void
    gear: Map<string, Gear>,
    tasks: Map<string, Task>
    gameEvents: Array<GameEvent>
    users: Map<string, UserId>
    desiredGearCount?: FormulaObject
    dailyDecrease: number
    initialized: boolean
}

export class MainPage extends React.PureComponent<MainPageProps, any> {

    render() {
        let maxGearCount = 5;
        this.props.gear.forEach((gear:Gear, _:string) => {
            if (!gear.suggested) {
                maxGearCount++;
            }
        })
        let availableGear = new Map<string, Gear>();
        this.props.gear.forEach((gear:Gear, gearId:string) => {
            if (!gear.suggested) {
                availableGear.set(gearId, {...gear, equipped: false});
            }
        })

        return <IonContent>
            <IonTabs >
                <IonRouterOutlet>
                    <Route path="/gear/" exact={true} children={() =>
                        <GearList key="gearList"  gameMaster={this.props.gameMaster}
                                  pushEvent={this.props.pushEvent}
                                  gear={this.props.gear}
                                  points={this.props.points}
                                  desiredGearCount={this.props.desiredGearCount}
                                  dailyDecrease={this.props.dailyDecrease}
                                  initialized={this.props.initialized}
                    />}/>
                    <Route path="/tasks/" exact={true} children={() =>
                        <TaskList key="taskList"
                                  gameMaster={this.props.gameMaster}
                                  tasks={this.props.tasks}
                                  points={this.props.points}
                                  pushEvent={this.props.pushEvent}
                                  initialized={this.props.initialized}
                        />}/>
                    <Route path="/history/" exact={true}>
                        <HistoryList key="history" events={this.props.gameEvents}
                                     onDelete={this.props.onDelete} users={this.props.users}
                                     gameMaster={this.props.gameMaster}
                                     points={this.props.points}
                        />
                    </Route>
                    <Route path={"/gear/add"}  children={({history}) =>
                        <GearDetails isGameMaster={this.props.gameMaster}
                                     history={history}
                                     gear={this.props.gear}
                                     pushEvent={this.props.pushEvent}/>}/>
                    <Route path={"/gear/edit/:gearId"}  children={({match, history}) =>
                        <GearDetails isGameMaster={this.props.gameMaster}
                                     gearId={match && match.params.gearId} history={history}
                                     gear={this.props.gear}
                                     pushEvent={this.props.pushEvent}/>}/>
                    <Route path={"/gear/desiredCount"} children={({history}) =>
                        <DesiredGearCount isGameMaster={this.props.gameMaster}
                                          pushEvent={this.props.pushEvent}
                                          desiredGearCount={this.props.desiredGearCount}
                                          maximumGearCount={maxGearCount}
                                          history={history}/>
                    }/>
                    <Route path={"/gear/dailyDecrease"} children={({history}) =>
                        <DailyDecrease isGameMaster={this.props.gameMaster}
                                       pushEvent={this.props.pushEvent}
                                       desiredGearCount={this.props.desiredGearCount}
                                       dailyDecrease={this.props.dailyDecrease}
                                       availableGear={availableGear}
                                       history={history} />
                    }/>
                    <Route path={"/tasks/add"} children={({history}) =>
                        <TaskDetails isGameMaster={this.props.gameMaster}
                                     pushEvent={this.props.pushEvent}
                                     history={history}
                                     tasks={this.props.tasks}
                                     />
                    }/>
                    <Route path={"/tasks/edit/:taskId"} children={({match, history}) =>
                        <TaskDetails isGameMaster={this.props.gameMaster}
                                     pushEvent={this.props.pushEvent}
                                     history={history} taskId={match && match.params.taskId}
                                     tasks={this.props.tasks}
                        />
                    }/>
                    <Route path={"/history/setPoints"} children={({history}) =>
                        <SetPointsPage currentPoints={this.props.points} isGameMaster={this.props.gameMaster}
                                       pushEvent={this.props.pushEvent}
                                       history={history}/>
                    }/>
                </IonRouterOutlet>
                <IonTabBar slot="bottom">
                    <IonTabButton tab="gearList" href="/gear/">
                        <IonIcon icon={paw} />
                        <IonLabel>Gear</IonLabel>
                    </IonTabButton>

                    <IonTabButton tab="taskList" href="/tasks/">
                        <IonIcon icon={clipboard} />
                        <IonLabel>Tasks</IonLabel>
                    </IonTabButton>

                    <IonTabButton tab="history" href="/history/">
                        <IonIcon icon={calendar} />
                        <IonLabel>History</IonLabel>
                    </IonTabButton>
                </IonTabBar>
            </IonTabs>
        </IonContent>;
    }
}