import React from "react";
import renderer from "@emeraldpay/hashicon/lib/renderer";
import {deepMerge} from "@emeraldpay/hashicon/lib/utils";
import {DefaultParams} from "@emeraldpay/hashicon/lib/params";
import {hash} from "tweetnacl-ts";

type HashiconProps = {
    value: string
    size: number
}

export class Hashicon extends React.PureComponent<HashiconProps, any> {
    private ref = React.createRef<HTMLCanvasElement>();

    componentDidMount() {
        this.renderHashicon();
    }

    componentDidUpdate(prevProps: Readonly<HashiconProps>, prevState: Readonly<any>, snapshot?: any) {
        this.renderHashicon();
    }

    renderHashicon() {
        if (this.ref.current != null) {
            let myparams = deepMerge(DefaultParams, {size: this.props.size, hasher: "keccak",createCanvas: () =>
                    this.ref.current as HTMLCanvasElement
            })
            renderer(new Uint16Array(hash(new TextEncoder().encode(this.props.value))), myparams);
        }
    }

    render() {
        let ReactElement = React.forwardRef<HTMLCanvasElement>((props, ref) =>
            <canvas ref={ref} width={this.props.size} height={this.props.size}></canvas>);
        let canvasElement = <ReactElement ref={this.ref}></ReactElement>
        return <div style={{float:"left", clear:"both", paddingRight: "8px"}}>{canvasElement}</div>;
    }
}