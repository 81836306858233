import React from "react";
import { IonApp, IonRouterOutlet, IonSplitPane, setupIonicReact } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {Redirect, Route} from 'react-router-dom';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './App.css';
import './theme/variables.css';
import {Menu} from "./components/Menu";
import {PrivacyPage} from "./pages/PrivacyPage";
import {InvitePlayer} from "./pages/InvitePlayer";
import {StartGame} from "./pages/StartGame";
import firebase from 'firebase/compat/app';
import {FirebaseApp} from "firebase/app";
import {getDatabase} from "firebase/database";
import {Sync, UserId} from "./Sync";
import {GameEvent, StateMachine, ValidationResult} from "./StateMachine";
import {GameAccess} from "./UserMgmt";
import {MainPage} from "./pages/MainPage";
import Welcome from "./pages/Welcome";
import {History} from "history";

setupIonicReact();

type AppState = {
  gameAccess?: GameAccess,
  stateMachine: StateMachine,
  gameRunning: boolean,
  gameEvents: Array<GameEvent>,
  users: Map<string, UserId>,
  points: number,
  menuOpen: boolean
}

export class App extends React.Component<any, AppState> {

  constructor(props: any) {
    super(props);
    this.state = {
      stateMachine: new StateMachine(),
      points: 0,
      gameRunning: false,
      gameEvents: new Array<GameEvent>(),
      users: new Map<string, UserId>(),
      menuOpen: this.props.isWeb
    };
  }

  readonly fireBaseApp: FirebaseApp = App.initFireBase();
  private sync?: Sync;
  private interval?: number;

  componentDidMount() {
    this.interval = window.setInterval(() => {
      this.setState({points: this.state.stateMachine.calculatePoints(Date.now())})
    }, 10000);
  }

  componentWillUnmount() {
    if (this.interval !== undefined) {
      window.clearInterval(this.interval);
      this.interval = undefined;
    }
  }

  static initFireBase(): FirebaseApp {
    const firebaseConfig = {
      apiKey: "AIzaSyAyTf3FRjVT_lqu06Xr09QJu3TsBykZxP8",
      authDomain: "perkin-3e3fe.firebaseapp.com",
      projectId: "perkin-3e3fe",
      storageBucket: "perkin-3e3fe.appspot.com",
      messagingSenderId: "340405565652",
      databaseURL: "https://perkin-3e3fe-default-rtdb.europe-west1.firebasedatabase.app",
      appId: "1:340405565652:web:c0e5281289e878ea392341"
    };
    return firebase.initializeApp(firebaseConfig);
  }

  onLoadGame = (history: History, gameAccess: GameAccess) => {
    this.setState({gameRunning: true, gameAccess: gameAccess}, () => {
      this.sync = new Sync(getDatabase(this.fireBaseApp));
      this.sync.loadGame(gameAccess);
      this.sync.addEventListener(this.onEventSync);
      this.sync.addUserListener(this.onUserSync);
      history.push("/gear/")
    })
  }

  onEventSync = (gameEvents: Array<GameEvent>, users: Map<string, UserId>): void => {
    const checkedGameEvents: Array<GameEvent> = [];
    let stateMachine = new StateMachine();
    stateMachine.userMap = users;
    gameEvents.forEach(gameEvent => {
      let result: ValidationResult = stateMachine.handleNextEvent(gameEvent);
      checkedGameEvents.push({...gameEvent, validation: result});
    });
    this.setState({
      stateMachine: stateMachine,
      gameEvents: checkedGameEvents, points: stateMachine.calculatePoints(Date.now())
    });
  }

  onUserSync = (users: Map<string, UserId>): void => {
    console.log("Updated users", users);
    this.setState({users: users});
  }

  pushEvent = (event: GameEvent) => {
    this.sync?.addEvent(event);
    console.log("pushevent", event);
  }

  onDelete = (event: GameEvent, state: boolean) => {
    if (!this.state.gameAccess?.gameMaster || event.eventId === undefined) {
      return
    }
    this.sync?.removeEvent(event.eventId, state);
  }

  render() {
    return (
    <IonApp>
      <IonReactRouter>
        <IonSplitPane contentId="main">
          <Menu gameRunning={this.state.gameRunning}
                isGameMaster={!!this.state.gameAccess?.gameMaster}/>
          <IonRouterOutlet id="main">
            <Route path="/" exact={true}>
              <Welcome />
            </Route>
            <Route path={["/gear/", "/tasks/", "/history/"]} exact={false}>
              {this.state.gameRunning?
                <MainPage points={this.state.points}
                          gameMaster={this.state.gameAccess?.gameMaster === true}
                          pushEvent={this.pushEvent}
                          gear={this.state.stateMachine.getCurrentState().gear}
                          tasks={this.state.stateMachine.getCurrentState().tasks}
                          desiredGearCount={this.state.stateMachine.getCurrentState().desiredGearCount}
                          gameEvents={this.state.gameEvents}
                          onDelete={this.onDelete}
                          users={this.state.users}
                          dailyDecrease={this.state.stateMachine.getCurrentState().dailyDecrease}
                          initialized={this.state.stateMachine.getCurrentState().initialized}
                /> : <Redirect to={"/game"}/>
              }
            </Route>
            <Route path="/game" children={({history}) =>
                <StartGame firebaseApp={this.fireBaseApp} gameRunning={this.state.gameRunning}
                           onLoadGame={this.onLoadGame} history={history} />
            } />
            <Route path="/invitePlayer" exact={true}>
              { this.state.gameRunning ?
                  <InvitePlayer gameAccess={this.state.gameAccess}/>
                  : <Redirect to={"/game"}/>
              }
            </Route>
            <Route path="/privacy" exact={true}>
              <PrivacyPage />
            </Route>
          </IonRouterOutlet>
        </IonSplitPane>
      </IonReactRouter>
    </IonApp>
  )
  };
}
