import React from "react";
import {FormulaChart} from "../components/FormulaChart";
import {FormulaObject} from "../Math";
import {DesiredGearCountChange} from "../StateMachine";
import {renderHeader} from "../components/Utils";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent,
    IonItem, IonLabel,
    IonList,
    IonListHeader,
    IonPage,
    IonRange,
    IonToast
} from "@ionic/react";
import {History} from "history";

type DesiredGearCountProps = {
    isGameMaster: boolean
    pushEvent(event: DesiredGearCountChange): void
    desiredGearCount?: FormulaObject
    maximumGearCount: number
    history: History
}

type DesiredGearCountState = {
    baseValue: number
    difficulty: number
    desiredValue: number
}

export class DesiredGearCount extends React.PureComponent<DesiredGearCountProps, DesiredGearCountState> {

    constructor(props: DesiredGearCountProps) {
        super(props);
        this.state = this.getCurrentState();
    }

    getCurrentState() {
        return this.props.desiredGearCount === undefined ? {
            baseValue: 50,
            difficulty: 50,
            desiredValue: 1
        } : {
            baseValue: this.props.desiredGearCount.baseValue * 100,
            difficulty: this.props.desiredGearCount.difficulty * 100,
            desiredValue: this.props.desiredGearCount.desiredValue
        }
    }

    componentDidUpdate(prevProps: Readonly<DesiredGearCountProps>, prevState: Readonly<DesiredGearCountState>, snapshot?: any) {
        if (prevProps.desiredGearCount !== this.props.desiredGearCount) {
            this.setState(this.getCurrentState())
        }
    }

    save = () => {
        try {
            this.props.pushEvent({
                type: "desiredGearCountChange",
                count: FormulaObject.create().withMaxValue(1)
                    .withDesiredValue(this.state.desiredValue)
                    .withDifficulty(this.state.difficulty / 100)
                    .withBaseValue(this.state.baseValue / 100)
            });
            this.closePage();
        } catch (e) {
            console.log(e);
        }
    }

    closePage = () => {
        this.props.history.push("/gear/")
    }

    render() {
        const canModify = this.props.isGameMaster;
        let formula;
        let error: string | undefined;
        try {
            formula = FormulaObject.create().withMaxValue(1)
                .withDesiredValue(this.state.desiredValue)
                .withDifficulty(this.state.difficulty / 100)
                .withBaseValue(this.state.baseValue / 100);
        } catch (e) {
            if (e instanceof Error) {
                error = e.message;
            }
            formula = this.props.desiredGearCount === undefined?FormulaObject.create({desiredValue: 1, baseValue: 0.5, maxValue: 1, difficulty: 0.5}):this.props.desiredGearCount;
        }
        return <IonPage>
            {renderHeader("Desired Gear Count", true, undefined, this.closePage)}

            <IonContent fullscreen>
                {renderHeader("Desired Gear Count", false, undefined, this.closePage)}
                <IonCard>

                    <IonCardContent>

                        <p> This sets the amount of gear the player has to wear to get the most points. It
                            incentivises the player to wear more gear, while also preventing that a lot of points can be
                            gained
                            by wearing too much gear.</p>
                        <p> For each gear item the player is wearing, the amount of gear worn at the same time is
                            averaged. Once
                            an item is removed, this average is then fed into this function to calculate the percentage
                            of
                            points the player will get.</p>
                        <p> The difficulty is the percentage of the amount, that needs to be worn, to get {
                            Math.round(this.state.baseValue + (100 - this.state.baseValue) / 2)} % of the points.</p>

                    </IonCardContent>
                </IonCard>
                <IonList>
                    <IonListHeader>
                        Desired Gear Count: {this.state.desiredValue}
                    </IonListHeader>
                    {canModify &&
                        <IonItem>
                            <IonRange value={this.state.desiredValue} step={1} snaps={true} min={1}
                                      max={Math.max(1, this.props.maximumGearCount)}
                                      onIonChange={(e) => this.setState({desiredValue: e.detail.value as number})}>
                                <IonLabel slot="start">1</IonLabel>
                                <IonLabel slot="end">{Math.max(1, this.props.maximumGearCount)}</IonLabel>
                            </IonRange>
                        </IonItem>
                    }
                    <IonListHeader>
                        Base Percentage: {this.state.baseValue} %
                    </IonListHeader>
                    {canModify &&
                        <IonItem>
                            <IonRange value={this.state.baseValue} min={0} max={99}
                                      onIonChange={(e) => this.setState({baseValue: e.detail.value as number})}>
                                <IonLabel slot="start">0%</IonLabel>
                                <IonLabel slot="end">99%</IonLabel>
                            </IonRange>
                        </IonItem>
                    }
                    <IonListHeader>
                        Difficulty: {this.state.difficulty} %
                    </IonListHeader>
                    {canModify &&
                        <IonItem>
                            <IonRange value={this.state.difficulty} min={0} max={99}
                                      onIonChange={(e) => this.setState({difficulty: e.detail.value as number})}>
                                <IonLabel slot="start">0%</IonLabel>
                                <IonLabel slot="end">99%</IonLabel>
                            </IonRange>
                        </IonItem>
                    }
                </IonList>

                <section style={{padding: "8px"}}>
                    <FormulaChart formula={formula} label="Percentage"/>
                </section>

                {canModify &&
                    <IonButton expand="block" onClick={this.save}>Save</IonButton>
                }

                <IonToast key="error" isOpen={error !== undefined} message={error} duration={5000}/>
            </IonContent>
        </IonPage>
    }
}