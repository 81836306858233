import React from "react";
import {gearSorter, parseIntOrDefault, renderHeader} from "../components/Utils";
import {GearListItem} from "../components/GearListItem";
import {FormulaObject} from "../Math";
import {DailyDecreaseChange, Gear} from "../StateMachine";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent,
    IonInput,
    IonItem, IonLabel,
    IonList,
    IonPage,
    IonTitle
} from "@ionic/react";
import {History} from "history";

export type DailyDecreaseProps = {
    isGameMaster: boolean
    dailyDecrease: number
    availableGear: Map<string, Gear>
    pushEvent(event: DailyDecreaseChange): void
    desiredGearCount?: FormulaObject
    history: History
}

export type DailyDecreaseState = {
    dailyDecrease: number
    selectedGear: Set<string>
}

export class DailyDecrease extends React.PureComponent<DailyDecreaseProps, DailyDecreaseState> {
    constructor(props: DailyDecreaseProps) {
        super(props);
        this.state = {
            dailyDecrease: props.dailyDecrease,
            selectedGear: new Set<string>()
        }
    }

    componentDidUpdate(prevProps: Readonly<DailyDecreaseProps>, prevState: Readonly<DailyDecreaseState>, snapshot?: any) {
        if (prevProps.dailyDecrease !== this.props.dailyDecrease) {
            this.setState({dailyDecrease: this.props.dailyDecrease});
        }
    }

    closePage = () => {
        this.props.history.push("/gear/")
    }

    save = () => {
        this.pushEventAndClose(this.state.dailyDecrease);
    }

    acceptRecommendation = () => {
        this.pushEventAndClose(this.calculateRecommendation(this.state.selectedGear));
    }

    pushEventAndClose(value: number) {
        this.props.pushEvent({
            type: "dailyDecreaseChange",
            dailyDecrease: value
        })
        this.closePage()
    }

    onGearChange = (gear: Gear, state: boolean) => {
        this.setState((prevState, _) => {
            let selectedGear = new Set(prevState.selectedGear);
            if (state) {
                selectedGear.add(gear.id);
            } else {
                selectedGear.delete(gear.id);
            }
            return {selectedGear: selectedGear}
        })
    }

    calculateRecommendation(gearList: Set<string>): number {
        let sum = 0;
        let count = 0;
        gearList.forEach((gearId) => {
            let gear = this.props.availableGear.get(gearId);
            if (gear !== undefined) {
                sum += gear.desiredDuration.points / (gear.desiredDuration.duration / 24);
                count++;
            }
        })
        return sum * (this.props.desiredGearCount === undefined? 1 : this.props.desiredGearCount.calculate(count));
    }

    render() {
        const canModify = this.props.isGameMaster
        return <IonPage>
            {renderHeader("Daily Decrease", true, undefined, this.closePage)}

            <IonContent fullscreen>
                {renderHeader("Daily Decrease", false, undefined, this.closePage)}
                <IonCard>
                    <IonCardContent>
                        Points are continuously removed from the player score. With the current value, the player needs
                        to generate { Math.round(this.state.dailyDecrease / 24 * 100) / 100 } points per hour.
                    </IonCardContent>
                </IonCard>
                <IonList>
                    <IonItem>
                        <IonLabel>Daily Decrease:</IonLabel>
                        {canModify ?
                            <IonInput id="daily-decrease-input" type="number" placeholder="Daily Decrease"
                                      value={this.state.dailyDecrease} min="1"
                                      onIonChange={(event) => typeof event.detail.value === "string" &&
                                          event.detail.value !== "" &&
                                          this.setState({
                                          dailyDecrease: parseIntOrDefault(event.detail.value, 0)
                                      })}
                            />
                            : <IonLabel>{this.props.dailyDecrease}</IonLabel>
                        }
                    </IonItem>
                </IonList>

                {canModify &&
                    <IonButton expand="block" onClick={this.save}>Save</IonButton>
                }

                {this.props.isGameMaster &&
                    <IonCard>
                        <IonCardContent>
                            <p>
                                You can select the gear that the player should wear all day to get a recommendation for
                                the
                                daily
                                decrease. The daily decrease should be set, so that the points should not decrease if
                                the
                                player is dutifully wearing that gear.
                            </p>
                            <p>
                                For the selected gear below, the decrease should
                                be {this.calculateRecommendation(this.state.selectedGear)}
                            </p>
                        </IonCardContent>
                    </IonCard>
                }

                {this.props.isGameMaster &&
                    <IonTitle>Selected all day gear</IonTitle>
                }
                {this.props.isGameMaster &&
                    <IonList>
                        {
                            Array.from(this.props.availableGear.values())
                                .sort(gearSorter)
                                .filter((gear) => this.state.selectedGear.has(gear.id))
                                .map((gear) => {return {...gear, equipped: true} as Gear})
                                .map((gear) => <GearListItem key={gear.id}
                                                             gear={gear}
                                                             showCheckBox={true}
                                                             desiredGearCount={this.props.desiredGearCount}
                                                             onChange={this.onGearChange}
                                                             disableLink={true} showLiveStats={false} />)
                        }
                    </IonList>
                }
                {this.props.isGameMaster &&
                    <IonTitle>Available gear</IonTitle>
                }
                {this.props.isGameMaster &&
                    <IonList>
                        {
                            Array.from(this.props.availableGear.values())
                                .sort(gearSorter)
                                .filter((gear) => !this.state.selectedGear.has(gear.id))
                                .map((gear) => {return {...gear, equipped: false} as Gear})
                                .map((gear) => <GearListItem key={gear.id}
                                                             gear={gear}
                                                             showCheckBox={true}
                                                             desiredGearCount={this.props.desiredGearCount}
                                                             onChange={this.onGearChange}
                                                             disableLink={true} showLiveStats={false} />)
                        }
                    </IonList>
                }

                {this.props.isGameMaster && canModify &&
                    <IonButton expand="block" onClick={this.acceptRecommendation}>Accept recommendation</IonButton>
                }
            </IonContent>
        </IonPage>
            ;
    }
}