import React from "react";
import {parseIntOrDefault, renderDifficulty, renderHeader} from "../components/Utils";
import {FormulaChart} from "../components/FormulaChart";
import {DurationFormulaCalc} from "../Math";
import cuid from "cuid";
import {
    AddOrModifyGear, Gear,
    RemoveGear,
    SuggestGear
} from "../StateMachine";
import {
    IonButton,
    IonCard, IonCardContent,
    IonContent,
    IonInput,
    IonItem,
    IonLabel,
    IonList, IonListHeader,
    IonPage,
    IonRange,
    IonToast
} from "@ionic/react";
import {History} from "history";

type GearDetailsProps = {
    isGameMaster: boolean
    gear: Map<string, Gear>,
    pushEvent(event: AddOrModifyGear | SuggestGear | RemoveGear): void
    history: History
    gearId?: string | null
}

type GearDetailsState = {
    gear?: Gear
    title: string
    difficulty: number
    desiredValue: number
    points: number
    increaseAfterSuccess: number
}

export class GearDetails extends React.PureComponent<GearDetailsProps, GearDetailsState> {

    constructor(props: GearDetailsProps) {
        super(props);
        this.state = this.getCurrentState();
    }

    getCurrentState(): GearDetailsState {
        let gear = undefined;
        if (this.props.gearId) {
            gear = this.props.gear.get(this.props.gearId);
        }
        console.log(gear, this.props.gearId);
        return {
            gear: gear,
            title: gear ? gear.title : "",
            difficulty: gear ? gear.desiredDuration.difficulty : 2,
            desiredValue: gear ? gear.desiredDuration.duration : 24,
            points: gear ? gear.desiredDuration.points : 25,
            increaseAfterSuccess: gear ? gear.desiredDuration.increaseAfterSuccess * 100 : 100
        };
    }

    componentDidUpdate(prevProps: Readonly<GearDetailsProps>, prevState: Readonly<GearDetailsState>, snapshot?: any) {
        if (prevProps.gearId !== this.props.gearId || prevProps.gear !== this.props.gear) {
            this.setState(this.getCurrentState());
        }
    }

    closePage = () => {
        this.props.history.push("/gear/")
    }

    removeGear = () => {
        if (this.state.gear) {
            this.props.pushEvent({
                type: "removeGear",
                gear: this.state.gear
            })
            this.closePage()
        }
    }

    saveGear = () => {
        try {
            const formula = DurationFormulaCalc.create({
                duration: this.state.desiredValue,
                difficulty: this.state.difficulty,
                points: this.state.points,
                increaseAfterSuccess: this.state.increaseAfterSuccess / 100
            });
            const id = this.state.gear ? this.state.gear.id : cuid();
            this.props.pushEvent({
                type: this.props.isGameMaster ? "addOrModifyGear" : "suggestGear",
                gear: {
                    id: id,
                    desiredDuration: formula,
                    title: this.state.title
                } as Gear
            })
            this.closePage()
        } catch (e) {
            console.log(e)
        }
    }

    render() {
        const canModify = this.state.gear === undefined || this.props.isGameMaster || (this.state.gear && this.state.gear.suggested);
        const title = this.props.isGameMaster ?
            (this.state.gear === undefined ? "Add Gear" : (
                this.state.gear.suggested ? "Accept Gear" : "Modify Gear")) :
            (canModify ? "Suggest Gear" : "Gear Details")
        let formula;
        let error: string | undefined;
        try {
            formula = DurationFormulaCalc.create({
                duration: this.state.desiredValue,
                difficulty: this.state.difficulty,
                points: this.state.points,
                increaseAfterSuccess: this.state.increaseAfterSuccess / 100
            });
        } catch (e) {
            if (e instanceof Error) {
                error = e.message;
            }
            formula = DurationFormulaCalc.create({
                duration: 24,
                difficulty: 1,
                points: 25,
                increaseAfterSuccess: 1
            });
        }
        if (this.state.title.length === 0) {
            error = "You must provide a gear name."
        }

        return <IonPage>
            {renderHeader(title, true, undefined, this.closePage)}

            <IonContent fullscreen>
                {renderHeader(title, false, undefined, this.closePage)}
            <IonCard>
                <IonCardContent>
                <p> The player will get {this.state.points} points for wearing the gear for the desired
                    duration of {this.state.desiredValue} hours. Afterwards the points will increase
                    by {Math.round(this.state.points / this.state.desiredValue * 100) / 100} points per hour
                    or {Math.round(this.state.points / (this.state.desiredValue / 24) * 100) / 100} points per day.
                </p>

                <p> The difficulty changes how much points the player will get before the desired duration.</p>
                </IonCardContent>
            </IonCard>

            <IonList>

                <IonItem>
                    <IonLabel slot="start">Name: </IonLabel>

                        {canModify ? <IonInput id="gear-name-input" type="text" style={{width:"100%"}} value={this.state.title}
                                            onIonChange={(e) =>
                                                this.setState({title: e.detail.value as string})}
                        /> : <IonLabel>{ this.state.title }</IonLabel>}

                </IonItem>
                <IonItem>
                    <IonLabel slot="start">Points: </IonLabel>
                        {canModify ?
                            <IonInput type="number" placeholder="Points"
                                   min="1" max="10000" value={this.state.points.toString()}
                                   onIonChange={(e) =>
                                       this.setState({points: parseIntOrDefault(e.detail.value, 1)})}
                            /> : <IonLabel>{ this.state.points }</IonLabel>}
                </IonItem>
                <IonItem>
                    <IonLabel slot="start">Desired duration: </IonLabel>
                    {canModify ?
                            <IonInput type="number" placeholder="Desired duration"
                                    min="1" max="168" value={this.state.desiredValue.toString()}
                                   onIonChange={(e) =>
                                       this.setState({desiredValue: parseIntOrDefault(e.detail.value, 1)})}
                            /> : <IonLabel>{ this.state.desiredValue } hours</IonLabel>}
                    {canModify && <IonLabel slot="end">hours</IonLabel>}
                </IonItem>

                {canModify && <IonListHeader>
                    Difficulty: {renderDifficulty(this.state.difficulty)}
                </IonListHeader>}
                <IonItem>
                    {!canModify && <IonLabel slot="start">Difficulty: </IonLabel> }
                    {!canModify && <IonLabel>{renderDifficulty(this.state.difficulty)}</IonLabel> }
                    {canModify &&
                            <IonRange value={this.state.difficulty} min={1} max={5} disabled={!canModify}
                                         onIonChange={(e) => this.setState({difficulty: e.detail.value as number})}
                            >
                                <IonLabel slot="start">easy</IonLabel>
                                <IonLabel slot="end">expert</IonLabel>
                            </IonRange>}
                </IonItem>

            </IonList>

            <section style={{padding: "8px"}}>
                <FormulaChart formula={formula} label="Points"/>
            </section>

            {canModify &&
                <IonButton disabled={error !== undefined} expand={"block"}
                           onClick={this.saveGear}>{title}</IonButton>
            }
            {
                this.props.isGameMaster && this.state.gear !== undefined &&
                <IonButton onClick={this.removeGear} expand={"block"} color={"danger"}>Delete gear</IonButton>
            }

            <IonToast key="gearDetailsError" isOpen={error !== undefined} message={error} duration={5000}></IonToast>
            </IonContent>
        </IonPage>;
    }
}