import {getAuth, signInAnonymously, User, Auth, onAuthStateChanged} from "firebase/auth";
import {Database, getDatabase, get, ref, DatabaseReference, child, set, Unsubscribe} from "firebase/database";
import {FirebaseApp} from "firebase/app";
import {randomBytes} from "tweetnacl-ts/es/random";
import {decodeBase64, encodeBase64} from "tweetnacl-ts/es/client/convert";
import {scrypt} from "scrypt-js/scrypt"
import {secretbox, secretbox_open} from "tweetnacl-ts/es/secretbox";
import {hash} from "tweetnacl-ts/es/hash";
import {verify} from "tweetnacl-ts/es/verify";

export type UserState = LoggedInState | LoggedOutState;
type LoggedInState = {
    gameId?: string
    userId: string
    isLoggedIn: true
}

type LoggedOutState = {
    gameId?: string
    isLoggedIn: false
}

export type GameAccess = BaseAccess & ({
    gameMaster: false
} | {
    gameMaster: true,
    gameMasterToken: Uint8Array, // 16 byte
})

type BaseAccess = {
    userId: string,
    gameId: Uint8Array, // 16 byte
    gameId64: string
    gameSecretKey: Uint8Array // 32 byte
}

export type EncryptedValue = {
    nonce: string,
    value: string
}

export class UserMgmt {
    private auth: Auth;
    private database: Database;
    private dbRef: DatabaseReference;
    private localStorage: Storage;
    private userState?: UserState;
    private listener?: Unsubscribe;
    private callback?(userState: UserState): void;


    constructor(app: FirebaseApp) {
        this.auth = getAuth(app);
        this.database = getDatabase(app);
        this.dbRef = ref(this.database);
        this.localStorage = window.localStorage;
    }

    onAuthStateChanged = (user: User | null): void => {
        if (user === null) {
            this.userState = {isLoggedIn: false};
            if (this.callback) {
                this.callback(this.userState);
            }
            signInAnonymously(this.auth).catch(e => console.log(e))
        } else {
            this.userState = {
                isLoggedIn: true,
                userId: user.uid
            };
            if (this.callback) {
                this.callback(this.userState);
            }
        }

        console.log(user);
    }

    addListener(callback: (userState: UserState) => void) {
        this.callback = callback;
        if (this.listener !== undefined) {
            this.listener()
        }
        this.listener = onAuthStateChanged(this.auth, this.onAuthStateChanged);
    }

    removeListener() {
        this.callback = undefined;
        if (this.listener !== undefined) {
            this.listener()
            this.listener = undefined
        }
    }

    loadGame = async (value: string, password: string, userId: string) => {
        let gameAccess = await UserMgmt.parseHash(UserMgmt.decodeToken(value), password, userId);
        let encryptedValue = await this.getAccessCheck(gameAccess.gameId64);

        let checkValue = secretbox_open(
                decodeBase64(encryptedValue.value),
                decodeBase64(encryptedValue.nonce),
                gameAccess.gameSecretKey);
        if (checkValue === undefined || !verify(checkValue,gameAccess.gameId)) {
            throw new Error("Invalid password!")
        }
        let isUser = await this.checkIfUserIsPartOfGame(gameAccess.gameId64, userId);
        return {
            ...gameAccess,
            isUser: isUser
        }
    }

    static decodeToken(token: string): Uint8Array {
        return decodeBase64(token.slice(1).replaceAll("-","+").replaceAll("_", "/"))
    }

    private checkIfUserIsPartOfGame = async (gameId: string, userId: string) => {
        try {
            let snapshot = await get(child(this.dbRef, "game/" + gameId + "/player/" + userId ));
            return snapshot.exists();
        } catch (e) {
            console.log(e);
            return false;
        }
    }

    private getAccessCheck = async (gameId: string): Promise<EncryptedValue> => {
        let snapshot = await get(child(this.dbRef, "game/" + gameId + "/token/check"))
        if (snapshot.exists()) {
            const value = snapshot.val() as EncryptedValue;
            if (typeof value !== "object" || value.nonce === undefined || value.value === undefined) {
                throw new Error("Invalid object type");
            }
            return value;
        } else {
            throw new Error("Game does not exists");
        }
    }

    static createToken() {
        return randomBytes(this.TokenLength);
    }

    static readonly NonceLength = 24
    static readonly KeyLength = 32
    static readonly TokenLength = 12

    static encrypt(msg: Uint8Array, key: Uint8Array): EncryptedValue {
        let nonce = randomBytes(this.NonceLength);
        return {
            nonce: encodeBase64(nonce),
            value: encodeBase64(secretbox(msg, nonce, key))
        }
    }

    static encodeToken(array: Uint8Array) {
        return "T" + encodeBase64(array)
            .replaceAll("+","-")
            .replaceAll("/", "_")
    }

    static createHash = async (gameAccess: GameAccess, password: string) => {
        const nonce = UserMgmt.createNonce(gameAccess.gameId);
        const key = await UserMgmt.kdf(password, hash(nonce));
        const msg = new Uint8Array(gameAccess.gameSecretKey.length +
            (gameAccess.gameMaster ? gameAccess.gameMasterToken.length: 0));
        msg.set(gameAccess.gameSecretKey, 0);
        if (gameAccess.gameMaster) {
            msg.set(gameAccess.gameMasterToken, gameAccess.gameSecretKey.length);
        }
        const encrypted = secretbox(msg, nonce, key);
        const final = new Uint8Array(nonce.length + encrypted.length);
        final.set(nonce, 0);
        final.set(encrypted, nonce.length);
        return UserMgmt.encodeToken(final);
    }

    static parseHash = async (inputValue: Uint8Array, password: string, userId: string): Promise<GameAccess> => {
        if (inputValue.length < this.NonceLength + this.KeyLength) {
            throw new Error("Hash too short.")
        }
        const nonce = inputValue.slice(0, this.NonceLength);
        const key = await UserMgmt.kdf(password, hash(nonce));
        const msg = secretbox_open(inputValue.slice(this.NonceLength), nonce, key);
        if (msg === undefined) {
            throw new Error("Invalid password.")
        }
        const gameId =  nonce.slice(0, this.TokenLength);
        if (msg.length < this.KeyLength) {
            throw new Error("Msg too short.")
        }
        const gameSecretKey = msg.slice(0, this.KeyLength);
        const gameMasterToken = msg.length >= this.KeyLength + this.TokenLength ?
            msg.slice(this.KeyLength, this.KeyLength+this.TokenLength): undefined;
        if (gameMasterToken !== undefined) {
            return {
                gameId: gameId,
                gameId64: UserMgmt.encodeToken(gameId),
                gameSecretKey: gameSecretKey,
                gameMaster: true,
                gameMasterToken: gameMasterToken,
                userId: userId
            }
        } else {
            return {
                gameId: gameId,
                gameId64: UserMgmt.encodeToken(gameId),
                gameSecretKey: gameSecretKey,
                gameMaster: false,
                userId: userId
            }
        }

    }

    static createNonce(gameId: Uint8Array): Uint8Array {
        if (gameId.length < 1 || gameId.length > this.NonceLength / 2) {
            throw new Error("Invalid gameId length");
        }
        const nonce = new Uint8Array(this.NonceLength);
        nonce.set(gameId, 0);
        nonce.set(randomBytes(this.NonceLength - gameId.length), gameId.length);
        return nonce;
    }

    static kdf = async (password: string, nonce: Uint8Array) => {
        const N = 16384, r = 8, p = 1;
        const dkLen = this.KeyLength;
        const passwordBytes = new TextEncoder().encode(password.normalize("NFKC"));
        return  await scrypt(passwordBytes, nonce, N, r, p, dkLen)
    }

    startNewGame = async (userId: string, playerName: string): Promise<GameAccess> => {
        let gameId = UserMgmt.createToken();
        const gameAccess: GameAccess = {
            gameId: gameId,
            gameId64: UserMgmt.encodeToken(gameId),
            gameMasterToken: UserMgmt.createToken(),
            gameSecretKey: randomBytes(UserMgmt.KeyLength),
            gameMaster: true,
            userId: userId
        }
        const gameToken64 = encodeBase64(hash(gameAccess.gameSecretKey));
        const gameMasterToken64 = encodeBase64(gameAccess.gameMasterToken);
        await set(child(this.dbRef, "/game/"+gameAccess.gameId64+"/token"), {
            game: gameToken64,
            gameMaster: gameMasterToken64,
            check: UserMgmt.encrypt(gameAccess.gameId, gameAccess.gameSecretKey)
        })
        await this.addUserToGame(gameAccess, playerName);
        return gameAccess
    }

    addUserToGame = async (gameAccess: GameAccess, playerName: string) => {
        const gameToken64 = encodeBase64(hash(gameAccess.gameSecretKey));
        let playerAccess;
        if (gameAccess.gameMaster) {
            playerAccess = {
                gameToken: gameToken64,
                gameMasterToken: encodeBase64(gameAccess.gameMasterToken)
            }
        } else {
            playerAccess = {
                gameToken: gameToken64
            }
        }
        await set(child(this.dbRef, "/game/" + gameAccess.gameId64 + "/playerAccess/" + gameAccess.userId), playerAccess)
        await set(child(this.dbRef, "/game/" + gameAccess.gameId64 + "/player/" + gameAccess.userId), {
            gameMaster: gameAccess.gameMaster,
            playerName: UserMgmt.encrypt(new TextEncoder().encode(playerName), gameAccess.gameSecretKey)
        })
    }
}