import {
    Database,
    set,
    ref,
    onChildAdded,
    onChildChanged,
    onChildRemoved,
    Unsubscribe,
    serverTimestamp,
    DataSnapshot
} from "firebase/database"
import cuid from 'cuid';
import {decodeBase64} from "tweetnacl-ts/es/client/convert";
import {BaseEvent, GameEvent} from "./StateMachine";
import {EncryptedValue, GameAccess, UserMgmt} from "./UserMgmt";
import {secretbox_open} from "tweetnacl-ts/es/secretbox";


type EncryptedEvent = EncryptedValue & {
    timestamp: object | number,
    userId: string,
    removed?: number | false
}

export type UserData = {
    readonly gameMaster: boolean
    readonly playerName: EncryptedValue
}

export type UserId = {
    readonly id: string
    readonly gameMaster: boolean
    readonly name: string
}

type Callback = (gameEvents: Array<GameEvent>, users: Map<string, UserId>) => void;
type UserCallback = (users: Map<string, UserId>) => void;

export class Sync {
    private readonly database: Database;
    private eventHandlers: Array<Unsubscribe> = [];
    private gameAccess?: GameAccess;
    private eventListeners: Array<Callback> = [];
    private userListeners: Array<UserCallback> = [];

    private eventList: Map<string, GameEvent> = new Map<string, GameEvent>();
    private userList: Map<string, UserId> = new Map<string, UserId>();
    private debounce: number = 0;

    constructor(database: Database) {
        this.database = database;
    }

    loadGame(gameAccess: GameAccess) {
        this.gameAccess = gameAccess;

        this.eventHandlers.forEach(unsubscribe => unsubscribe());
        this.eventHandlers = [];
        this.eventList = new Map<string, GameEvent>();
        this.userList = new Map<string, UserId>();
        this.debounce = Date.now();
        this.eventHandlers.push(onChildAdded(ref(this.database, "game/" + gameAccess.gameId64 + "/player"),
            this.onPlayerAdded,
            (e) => console.log(e)
        ));
        this.eventHandlers.push(onChildRemoved(ref(this.database, "game/" + gameAccess.gameId64 + "/player"),
            this.onPlayerRemoved,
            (e) => console.log(e)
        ));
        this.eventHandlers.push(onChildAdded(ref(this.database, "game/" + gameAccess.gameId64 + "/event"),
            this.onEventAddedOrChanged,
            (e) => console.log(e)
        ));
        this.eventHandlers.push(onChildChanged(ref(this.database, "game/" + gameAccess.gameId64 + "/event"),
            this.onEventAddedOrChanged,
            (e) => console.log(e)
        ));
        this.eventHandlers.push(onChildRemoved(ref(this.database, "game/" + gameAccess.gameId64 + "/event"),
            this.onEventRemoved,
            (e) => console.log(e)))
    }

    onPlayerAdded = (snapshot: DataSnapshot) => {
        if (snapshot.key === null) {
            console.log("Key is null");
            return;
        }
        if (this.gameAccess === undefined) {
            console.log("Game Keys undefined")
            return;
        }
        let userData: UserData = snapshot.val() as UserData;
        console.log("New player added", snapshot.key, userData);
        let decryptedName = secretbox_open(
            decodeBase64(userData.playerName.value),
            decodeBase64(userData.playerName.nonce),
            this.gameAccess.gameSecretKey)

        this.userList.set(snapshot.key, {
            id: snapshot.key,
            gameMaster: userData.gameMaster,
            name: new TextDecoder().decode(decryptedName),
        });
        this.debounce = Date.now();
        window.setTimeout(() => {
                if (this.debounce > 0 && this.debounce < Date.now() - 80) {
                    this.debounce = 0
                    this.userListeners.forEach(listener => listener(new Map(this.userList)));
                    this.eventListeners.forEach(listener => listener(this.getAllEventsInChronologicalOrder(), this.userList));
                }
            }, 100);
    }


    onPlayerRemoved = (snapshot: DataSnapshot) => {
        if (snapshot.key === null) {
            console.log("Key is null");
            return;
        }
        console.log("Player removed", snapshot.key, snapshot.val());

        this.userList.delete(snapshot.key);
        this.debounce = Date.now();
        window.setTimeout(() => {
            if (this.debounce > 0 && this.debounce < Date.now() - 80) {
                this.debounce = 0
                this.userListeners.forEach(listener => listener(new Map(this.userList)));
                this.eventListeners.forEach(listener => listener(this.getAllEventsInChronologicalOrder(), this.userList));
            }
        }, 100);
    }

    onEventAddedOrChanged = (snapshot: DataSnapshot) => {
        if (snapshot.key === null) {
            console.log("Key is null");
            return;
        }
        if (this.gameAccess === undefined) {
            console.log("Game Keys undefined")
            return;
        }
        let event: EncryptedEvent = snapshot.val() as EncryptedEvent;
        console.log("Encrypted event", snapshot.key, event);
        let gameEventBytes = secretbox_open(
            decodeBase64(event.value),
            decodeBase64(event.nonce),
            this.gameAccess.gameSecretKey);
        let gameEvent = JSON.parse(new TextDecoder().decode(gameEventBytes)) as GameEvent;
        if (typeof event.timestamp === "number") {
            gameEvent.timestamp = event.timestamp;
        }
        gameEvent.userId = event.userId;
        gameEvent.eventId = snapshot.key;
        if (event.removed !== undefined) {
            gameEvent.removed = event.removed !== false;
        }
        console.log("Event", gameEvent);
        this.eventList.set(snapshot.key, gameEvent);
        this.debounce = Date.now();
        window.setTimeout(() => {
            if (this.debounce > 0 && this.debounce < Date.now() - 80) {
                this.debounce = 0
                this.userListeners.forEach(listener => listener(new Map(this.userList)));
                this.eventListeners.forEach(listener => listener(this.getAllEventsInChronologicalOrder(), this.userList));
            }
        }, 100);
    }

    onEventRemoved = (snapshot: DataSnapshot) => {
        if (snapshot.key === null) {
            console.log("Key is null");
            return;
        }
        console.log("Key", snapshot.key);
        let event: EncryptedEvent = snapshot.val() as EncryptedEvent;
        console.log("Removed snapshot", event);
        this.eventList.delete(snapshot.key);
        console.log(this.eventList);
        this.debounce = Date.now();
        window.setTimeout(() => {
            if (this.debounce > 0 && this.debounce < Date.now() - 40) {
                this.debounce = 0
                this.userListeners.forEach(listener => listener(new Map(this.userList)));
                this.eventListeners.forEach(listener => listener(this.getAllEventsInChronologicalOrder(), this.userList));
            }
        }, 50);
    }

    addEvent(event: GameEvent) {
        if (this.gameAccess === undefined) {
            console.log("Event added, while no game is running")
            return;
        }
        let data = new TextEncoder().encode(JSON.stringify(event));
        let encryptedData: EncryptedEvent = {
            ...UserMgmt.encrypt(data, this.gameAccess.gameSecretKey),
            userId: this.gameAccess.userId,
            timestamp: serverTimestamp()
        }
        set(ref(this.database, "game/" + this.gameAccess.gameId64 + "/event/" + cuid()), encryptedData)
            .catch((e: Error) => {
                console.log(e.message)
            });
    }

    removeEvent(eventId: string, state: boolean) {
        console.log(eventId, state);
        if (this.gameAccess === undefined) {
            console.log("Event removed, while no game is running")
            return;
        }
        set(ref(this.database, "game/" + this.gameAccess.gameId64 + "/event/" + eventId + "/removed"), state ? serverTimestamp() : false)
            .catch((e: Error) => {
                console.log(e.message)
            });
    }


    private static chronologicalSorter(a: BaseEvent, b: BaseEvent) {
        if (a.timestamp === undefined && b.timestamp === undefined) {
            return 0
        }
        if (a.timestamp === undefined) {
            return -1;
        }
        if (b.timestamp === undefined) {
            return 1;
        }
        if (a.timestamp < b.timestamp) {
            return -1;
        }
        if (a.timestamp > b.timestamp) {
            return 1;
        }
        if (a.eventId === undefined && b.eventId === undefined) {
            return 0;
        }
        if (a.eventId === undefined) {
            return -1;
        }
        if (b.eventId === undefined) {
            return 1;
        }
        if (a.eventId < b.eventId) {
            return -1;
        }
        if (a.eventId > b.eventId) {
            return 1;
        }
        return 0;
    }

    getAllEventsInChronologicalOrder(): Array<GameEvent> {
        return Array.from(this.eventList.values()).sort(Sync.chronologicalSorter)
    }

    addEventListener(onEventSync: Callback) {
        this.eventListeners.push(onEventSync);
    }

    addUserListener(onUserSync: UserCallback) {
        this.userListeners.push(onUserSync);
    }
}