import React from "react";
import {IonContent, IonList, IonMenu, IonRouterLink} from "@ionic/react";
import {documentText, enter, list, personAdd, trendingDown, trendingUp} from "ionicons/icons";
import './Menu.css';
import MenuItem from "./MenuItem";

type MenuProps = {
    gameRunning: boolean,
    isGameMaster: boolean
}

export class Menu extends React.PureComponent<MenuProps, any> {

    render() {
        return (
            <IonMenu contentId="main" type="overlay">
                <IonContent>
                    <header style={{textAlign: "left"}}>
                        <h2><IonRouterLink routerLink={this.props.gameRunning ? "/gear/" : "/"}>Permanently Kinky</IonRouterLink></h2>
                        <small>A game about wearing kinky gear and collecting points.</small>
                    </header>

                    {this.props.gameRunning &&
                        <IonList>
                            <MenuItem url={"/gear/desiredCount"} icon={list} title={"Desired gear count"} />
                            <MenuItem url={"/gear/dailyDecrease"} icon={trendingDown} title={"Daily decrease"} />
                            {this.props.isGameMaster &&
                                <MenuItem url={"/history/setPoints"} icon={trendingUp} title={"Set points"}/>
                            }
                        </IonList>
                    }

                    <IonList>
                        <MenuItem url={"/game"} icon={enter} title={"Start game"} />
                        {this.props.isGameMaster &&
                            <MenuItem url={"/invitePlayer"} icon={personAdd} title={"Invite player"} />
                        }
                        <MenuItem url={"/privacy"} icon={documentText} title={"Privacy policy"} />
                    </IonList>


                </IonContent>
            </IonMenu>);
    }
}