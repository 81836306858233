import React from "react";
import {Gear, StateMachine} from "../StateMachine";
import {formatTime, renderDifficulty} from "./Utils";
import {
    CheckboxChangeEventDetail,
    IonCheckbox,
    IonItem, IonItemOption, IonItemOptions,
    IonItemSliding,
    IonLabel
} from "@ionic/react";
import {FormulaObject} from "../Math";

type GearListItemProps = {
    gear: Gear
    showCheckBox: boolean
    desiredGearCount?: FormulaObject
    onChange(gear: Gear, value: boolean): void
    disableLink?: boolean
    showLiveStats: boolean
}

export class GearListItem extends React.PureComponent<GearListItemProps, any> {
    private interval?: number;

    componentDidMount() {
        if (this.props.gear.equipped) {
            this.interval = window.setInterval(this.forceUpdate.bind(this), 60000);
        }
    }

    componentWillUnmount() {
        if (this.interval !== undefined) {
            window.clearInterval(this.interval)
        }
    }

    onChange = (e: CustomEvent<CheckboxChangeEventDetail>) => {
        this.props.onChange(this.props.gear, e.detail.checked);
    }

    onSwipe = () => {
        this.props.onChange(this.props.gear, !this.props.gear.equipped);
    }

    render() {
        const formula = this.props.gear.desiredDuration;
        let stats = renderDifficulty(formula.difficulty) + ", " + formula.duration + "h, " + Math.floor((formula.points / formula.duration) * 100) / 100 + " P/h";
        if (this.props.showLiveStats && this.props.gear.equipped && this.props.gear.wornSince && this.props.desiredGearCount) {
            let result = StateMachine.calculateGearPoints(this.props.gear, Date.now(), this.props.desiredGearCount)
            let points = result === undefined ? 0 : result.points;
            const diffMinutes = result === undefined ? 0 : result.hours * 60;
            stats = renderDifficulty(formula.difficulty) + ", " + formatTime(diffMinutes) +
                " / " + formula.duration + "h = " + Math.floor(points * 100) / 100 + " / " + formula.points + " P";
        }
        let routerLink = this.props.disableLink === true ? undefined : "/gear/edit/" + this.props.gear.id;
        return <IonItemSliding>
            {this.props.showCheckBox && <IonItemOptions side="start" onIonSwipe={this.onSwipe}>
                <IonItemOption>
                    <span className="checkbox-container">
                        <IonCheckbox checked={this.props.gear.equipped} onIonChange={this.onChange} slot="start"/>
                    </span>
                </IonItemOption>
            </IonItemOptions>}
            <IonItem routerLink={routerLink} detail={true}>
                <IonLabel>{this.props.gear.title}</IonLabel>
                <IonLabel slot="end">{stats}</IonLabel>
            </IonItem>
        </IonItemSliding>
    }
}