import React from "react";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent, IonHeader,
    IonIcon,
    IonInput,
    IonItem,
    IonItemOption,
    IonItemOptions,
    IonItemSliding,
    IonLabel,
    IonList,
    IonListHeader,
    IonModal,
    IonPage,
    IonThumbnail,
    IonToast
} from "@ionic/react";
import {renderHeader} from "../components/Utils";
import {FirebaseApp} from "firebase/app";
import {GameAccess, UserMgmt, UserState} from "../UserMgmt";
import {lockOpen, personCircle, trash} from "ionicons/icons";
import {GameInfo, GameMgmt} from "../GameMgmt";
import {History} from "history";
import {Hashicon} from "../components/Hashicon";

type LoadGameProps = {
    id?: string
    firebaseApp: FirebaseApp,
    gameRunning: boolean
    onLoadGame(history: History, gameAccess: GameAccess): void
    openMenu?(): void
    history: History
}

type LoadGameState = BaseState & (LoggedInState | LoggedOutState);

type BaseState = {
    displayName?: string
    invitePassword?: string
    password?: string
    invite?: GameInfo
    selectedGame?: GameInfo,
    openDialog?: boolean
    error?: string
    showUserNameField?: boolean
}
type LoggedInState = {
    userId: string
    isLoggedIn: true
}

type LoggedOutState = {
    isLoggedIn: false
}

export class StartGame extends React.PureComponent<LoadGameProps, LoadGameState> {

    private userMgmt;
    private gameMgmt;
    private modal = React.createRef<HTMLIonModalElement>();

    constructor(props: LoadGameProps) {
        super(props);
        this.state = {
            isLoggedIn: false
        }
        this.userMgmt = new UserMgmt(props.firebaseApp);
        this.gameMgmt = new GameMgmt();
    }

    loginStateListener = (userState: UserState) => {
        this.setState(userState.isLoggedIn ? {
            isLoggedIn: true,
            userId: userState.userId
        } : {
            isLoggedIn: false
        })
    }

    componentDidMount() {
        this.userMgmt.addListener(this.loginStateListener)
        if (window.location.hash && window.location.hash.length > this.gameMgmt.hashMinLength) {
            let invite = window.location.hash.startsWith("#I");
            let gameHash = window.location.hash.slice(1);
            let gameInfo: GameInfo;
            if (!invite) {
                gameInfo = this.gameMgmt.findOrStoreGameState(gameHash);
                this.setState({
                    selectedGame: gameInfo,
                    openDialog: true
                })
            } else {
                gameInfo = this.gameMgmt.createNewGameInfo(gameHash, invite);
                this.setState({
                    selectedGame: gameInfo,
                    openDialog: true,
                    invite: gameInfo
                })
            }
        }
    }

    componentWillUnmount() {
        this.userMgmt.removeListener()
    }


    renderGame(item: GameInfo, backButton?: boolean): JSX.Element {
        return <IonItemSliding key={item.key}>
            <IonItem detail={true} onClick={this.selectGame(backButton === true?undefined:item)} button>
                <IonThumbnail slot="start">
                    <Hashicon value={item.value.slice(1, 17)} size={56}/>
                </IonThumbnail>
                {item.invite ?
                    <IonLabel>
                        You got an invite to join a game as {
                        item.gameMaster ? "GameMaster" : "Player"}
                    </IonLabel> :
                    <IonLabel>
                        Started at {item.time.toLocaleString()} as {
                        item.gameMaster ? "GameMaster" : "Player"}
                    </IonLabel>
                }
            </IonItem>
            {!item.invite && !backButton &&
                <IonItemOptions side="end">
                    <IonItemOption onClick={this.deleteGame(item.key)}>
                        <IonIcon icon={trash} size={"24px"}/>
                    </IonItemOption>
                </IonItemOptions>
            }
        </IonItemSliding>
    }

    renderGameList(): JSX.Element {
        let accessList = this.gameMgmt.loadGameList();
        return <section>
            {this.state.invite &&
                <section>
                    <IonListHeader>
                        Join invited game
                    </IonListHeader>
                    <IonList>
                        {this.renderGame(this.state.invite)}
                    </IonList>
                </section>
            }
            {accessList.length > 0 &&
                <section>
                    <IonListHeader>
                        Select game to continue
                    </IonListHeader>
                    <IonList>
                        {
                            accessList.map(item => this.renderGame(item))
                        }
                    </IonList>
                </section>
            }
        </section>
    }

    selectGame = (gameInfo?: GameInfo) => () => {
        console.log("selectGame", gameInfo);
        if (gameInfo) {
            this.props.history.push("/game#"+gameInfo.value)
        }
        this.setState({
            openDialog: gameInfo !== undefined,
            selectedGame: gameInfo
        })
    }

    deleteGame = (key: string) => () => {
        let ls = window.localStorage;
        ls.removeItem(key);
        this.forceUpdate();
    }

    playGame = async () => {
        if (!this.state.isLoggedIn) {
            return;
        }
        if (this.state.selectedGame === undefined) {
            return this.showError("No game selected");
        }
        let password: string;
        if (this.state.password === undefined ||
            this.state.password.length === 0) {
            return this.showError("Password missing.");
        } else {
            password = this.state.password;
        }
        if (this.state.selectedGame.invite) {
            if (this.state.invitePassword === undefined ||
                this.state.invitePassword.length === 0) {
                return this.showError("Invite Password missing.");
            } else {
                password = this.state.invitePassword;
            }
        }
        let selectedGame = this.state.selectedGame;
        try {
            let gameAccess = await this.userMgmt.loadGame(selectedGame.value, password, this.state.userId);

            if (!this.state.isLoggedIn) {
                return;
            }
            if (!gameAccess.isUser) {
                if (!this.state.displayName) {
                    this.setState({showUserNameField: true})
                    this.showError("Username lost, please provide one.")
                    return;
                } else {
                    await this.userMgmt.addUserToGame(gameAccess, this.state.displayName);
                }
            }

            let gameHash = selectedGame.value;
            if (selectedGame.invite) {
                gameHash = await UserMgmt.createHash(gameAccess, this.state.password);
                this.gameMgmt.findOrStoreGameState(gameHash);
            }
            this.modal.current?.dismiss();
            this.props.onLoadGame(this.props.history, gameAccess);
        } catch (error) {
            console.log(error)
            if (error instanceof Error) {
                this.showError(error.message)
            }
        }
    }

    startNewGame = async () => {
        if (!this.state.isLoggedIn) {
            return
        }
        if (this.state.displayName === undefined || this.state.displayName.length === 0) {
            return this.showError("Player name must be set");
        }
        if (this.state.password === undefined || this.state.password.length === 0) {
            return this.showError("Password must be set");
        }
        let userId = this.state.userId;
        const password = this.state.password;
        let gameAccess = await this.userMgmt.startNewGame(userId, this.state.displayName);
        let gameHash = await UserMgmt.createHash(gameAccess, password);
        let gameInfo = this.gameMgmt.findOrStoreGameState(gameHash);
        console.log("createdGame", gameInfo);
        this.selectGame(gameInfo)();
    }

    showError(error: string) {
        this.setState({error: error});
        window.setTimeout(() => this.setState((prevState, _) => prevState.error === error ?
            {error: undefined} : {}), 20000);
    }

    renderTitle() {
        return  <IonCard>
            <IonCardContent>
            <p>To protect your privacy, personal data is encrypted on the server. An access key will be stored in
                your browser encrypted by a password. If you clear your browser data,
                you loose access to the game, unless you create a bookmark of your game once it started.</p>
            </IonCardContent>
        </IonCard>
    }

    render() {
        return  <IonPage>
            {renderHeader("Load or start new game", true)}

            <IonContent fullscreen>
                {renderHeader("Load or start new game", false)}

            {this.renderTitle()}

            {
                this.renderGameList()
            }

            {this.state.isLoggedIn &&
                <section>
                    <IonListHeader>
                        Game settings
                    </IonListHeader>
                    <IonList>

                        <IonItem>
                            <IonIcon slot="start" icon={personCircle}/>
                            <IonInput value={this.state.displayName} placeholder={"Username"} style={{width: "100%"}}
                                      onIonChange={(e) => this.setState({
                                          displayName: e.detail.value as string
                                      })}/>
                        </IonItem>
                        <IonItem>
                            <IonIcon slot="start" icon={lockOpen}/>
                            <IonInput value={this.state.password} type="password" placeholder={"Password"} style={{width: "100%"}}
                                      onIonChange={(e) => this.setState({
                                          password: e.detail.value as string
                                      })}/>
                        </IonItem>
                    </IonList>
                    <IonButton expand="block" onClick={this.startNewGame}>Start new game as game
                        master!</IonButton>
                </section>
            }
            </IonContent>
            <IonToast key="error" isOpen={this.state.error !== undefined} message={this.state.error} duration={5000}/>
            <IonModal ref={this.modal} isOpen={this.state.isLoggedIn && this.state.openDialog === true} onDidDismiss={() => this.setState({openDialog: false})}>
                <IonHeader>
                    {this.state.selectedGame &&
                        this.renderGame(this.state.selectedGame, true)
                    }
                </IonHeader>
                <IonContent>
                    {this.state.selectedGame && !this.state.selectedGame.invite &&
                        <IonCard>
                            <IonCardContent>
                                <p>You can bookmark this page, if you want to return to exactly this game. If you
                                clear your browser data, you will loose access to the game otherwise.</p>
                            </IonCardContent>
                        </IonCard>
                    }
                    <IonList>

                        {this.state.selectedGame && this.state.selectedGame.invite &&
                            <IonItem>
                                <IonIcon slot="start" icon={lockOpen}/>
                                <IonInput type="password" placeholder="Invite Password" style={{width: "100%"}}
                                          onIonChange={e => this.setState({invitePassword: e.detail.value as string})}
                                />
                            </IonItem>
                        }
                        <IonItem>
                            <IonLabel>
                                {this.state.selectedGame && this.state.selectedGame.invite && "New "}Password:
                            </IonLabel>
                            <IonInput value={this.state.password}
                                      type={"password"} placeholder="Password" style={{width: "100%"}}
                                      onIonChange={e => this.setState({password: e.detail.value as string})}
                            />
                        </IonItem>
                        {(this.state.showUserNameField || (this.state.selectedGame && this.state.selectedGame.invite)) &&
                            <IonItem>
                                <IonLabel>Username</IonLabel>
                                <IonInput value={this.state.displayName} placeholder={"Username"}
                                          onIonChange={(e) => this.setState({
                                              displayName: e.detail.value as string
                                          })}/>
                            </IonItem>
                        }
                    </IonList>
                    <IonButton expand="block"
                               onClick={this.playGame}>{this.state.selectedGame && this.state.selectedGame.invite ? "Join" : "Continue"} Game</IonButton>
                </IonContent>
            </IonModal>
        </IonPage>;
    }
}