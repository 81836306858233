import React from "react";

import {parseIntOrDefault, renderHeader} from "../components/Utils";
import cuid from "cuid";
import {AddOrModifyTask, CompleteTask, RemoveTask, SuggestTask, Task} from "../StateMachine";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent,
    IonInput,
    IonItem, IonLabel, IonList,
    IonListHeader,
    IonPage, IonSelect, IonSelectOption,
    IonTextarea, IonToast, IonToggle
} from "@ionic/react";
import {History} from "history";

type TaskDetailsProps = {
    isGameMaster: boolean
    isSuggested?: boolean
    pushEvent(task: CompleteTask | AddOrModifyTask | SuggestTask | RemoveTask): void
    taskId?: string | null
    tasks: Map<string, Task>
    history: History
}

type TaskDetailsState = {
    task?: Task
    title: string
    description: string
    points: number
    requirement: "no" | "lt" | "gt" | "le" | "ge"
    requirementPoints: number
    unlimitedRepeats: boolean
    availableRepeats: number
    active: boolean
}

export class TaskDetails extends React.PureComponent<TaskDetailsProps, TaskDetailsState> {
    constructor(props: TaskDetailsProps) {
        super(props);
        this.state = this.getCurrentState();
    }

    getCurrentState() {
        let selectedTask = undefined;
        if (this.props.taskId) {
            selectedTask = this.props.tasks.get(this.props.taskId);
        }
        const task = selectedTask;
        return {
            task: task,
            title: task ? task.title : "",
            description: task ? task.description : "",
            points: task ? task.points : 100,
            requirement: task ? task.requirement : "no",
            requirementPoints: task && task.requirementPoints !== undefined ? task.requirementPoints : 0,
            unlimitedRepeats: task ? task.unlimitedRepeats : true,
            availableRepeats: task && task.availableRepeats !== undefined ? task.availableRepeats : 1,
            active: task ? task.active : true
        }
    }

    componentDidUpdate(prevProps: Readonly<TaskDetailsProps>, prevState: Readonly<TaskDetailsState>, snapshot?: any) {
        if (prevProps.taskId !== this.props.taskId || prevProps.tasks !== this.props.tasks) {
            this.setState(this.getCurrentState());
        }
    }

    closePage = () => {
        this.props.history.push("/tasks/")
    }

    saveTask = () => {
        try {
            const id = this.state.task ? this.state.task.id : cuid();
            this.props.pushEvent({
                type: this.props.isGameMaster ? "addOrModifyTask" : "suggestTask",
                task: {
                    id: id,
                    title: this.state.title,
                    description: this.state.description,
                    points: this.state.points,
                    requirement: this.state.requirement,
                    requirementPoints: this.state.requirement !== "no" ? this.state.requirementPoints : undefined,
                    unlimitedRepeats: this.state.unlimitedRepeats,
                    availableRepeats: !this.state.unlimitedRepeats ? this.state.availableRepeats : undefined,
                    active: this.state.active
                } as Task
            })
            this.closePage()
        } catch (e) {
            console.log(e)
        }
    }

    removeTask = () => {
        if (this.state.task) {
            this.props.pushEvent({
                type: "removeTask",
                task: this.state.task
            })
            this.closePage()
        }
    }

    render() {
        const canModify = this.state.task === undefined || this.props.isGameMaster || this.props.isSuggested;
        const title = this.props.isGameMaster ?
            (this.state.task === undefined ? "Add Task" : (
                this.props.isSuggested ? "Accept Task" : "Modify Task")) :
            (canModify ? "Suggest Task" : "Task Details")
        let error: string | undefined;
        if (this.state.title.length === 0) {
            error = "You must provide a task name."
        }
        return <IonPage>
            {renderHeader(title, true, undefined, this.closePage )}

            <IonContent fullscreen>
                {renderHeader(title, false, undefined, this.closePage)}
                <IonCard>
                    <IonCardContent>
                Tasks can be done by the player at any time to gain or loose points.
                    </IonCardContent>
                </IonCard>

            <IonList>
                <IonListHeader>Task title</IonListHeader>
                <IonItem>
                        {canModify ?
                            <IonInput type="text" placeholder="Task title"
                                   value={this.state.title}
                                   onIonChange={e => this.setState({title: e.detail.value as string})}/>
                            : this.state.title}
                </IonItem>
                <IonListHeader>Task description</IonListHeader>
                <IonItem>
                        {canModify ?
                            <IonTextarea className="textarea" rows={3} placeholder="Task description"
                                      style={{width: "100%"}} value={this.state.description}
                                      onIonChange={e => this.setState({description: e.detail.value as string})}/>
                            : this.state.description}
                </IonItem>
                <IonListHeader>Points</IonListHeader>
                <IonItem>
                        {canModify ?
                            <IonInput type="number" placeholder="Points"
                                   value={this.state.points + ""}
                                   onIonChange={e => this.setState({points: parseIntOrDefault(e.detail.value, 0)})}/>
                            : this.state.points}
                </IonItem>
                <IonListHeader>Requirement</IonListHeader>
                <IonItem>
                        <IonSelect slot="start" value={this.state.requirement}
                                onIonChange={e => this.setState({requirement: e.detail.value})}
                                disabled={!canModify}>
                            <IonSelectOption value="no">No</IonSelectOption>
                            <IonSelectOption value="lt">&lt;</IonSelectOption>
                            <IonSelectOption value="le">&lt;=</IonSelectOption>
                            <IonSelectOption value="gt">&gt;</IonSelectOption>
                            <IonSelectOption value="ge">&gt;=</IonSelectOption>
                        </IonSelect>
                        {this.state.requirement !== "no" &&
                            (canModify ?
                                <IonInput type="number"
                                       value={this.state.requirementPoints + ""}
                                       onIonChange={e => this.setState({requirementPoints: parseIntOrDefault(e.detail.value, 1)})}
                                />
                                : this.state.requirementPoints)
                        }
                        {this.state.requirement !== "no" &&
                            <IonLabel slot="end">Points</IonLabel>
                        }
                </IonItem>
                <IonListHeader>Repeatability</IonListHeader>
                <IonItem>
                    <IonLabel>
                        Unlimited
                    </IonLabel>
                    <IonToggle slot="end" checked={this.state.unlimitedRepeats}
                                onIonChange={e => this.setState({unlimitedRepeats: e.detail.checked})}
                                disabled={!canModify}/>
                </IonItem>
                {!this.state.unlimitedRepeats &&
                    <IonItem>
                        <IonLabel slot="start">
                            Complete
                        </IonLabel>
                        {canModify ?
                                <IonInput type="number" value={this.state.availableRepeats + ""}
                                       onIonChange={e => this.setState({availableRepeats: parseIntOrDefault(e.detail.value, 0)})}
                                />
                                : this.state.availableRepeats}
                        <IonLabel slot="end">
                            times
                        </IonLabel>
                    </IonItem>
                }
                <IonItem>
                    <IonLabel>
                        Active
                    </IonLabel>
                    <IonToggle slot="end" checked={this.state.active}
                                onIonChange={e => this.setState({active: e.detail.checked})}
                                disabled={!canModify}></IonToggle>
                </IonItem>
            </IonList>

            {canModify &&
                <IonButton expand={"block"}
                        onClick={this.saveTask}
                disabled={error !== undefined}>{title}</IonButton>
            }
            {canModify &&
                <IonButton expand={"block"} color={"danger"}
                    onClick={this.removeTask}
                    disabled={error !== undefined}>Remove Task</IonButton>
            }
            <IonToast key="taskDetailsError" isOpen={error !== undefined} message={error} duration={5000}/>
            </IonContent>
        </IonPage>;
    }
}