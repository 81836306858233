

export type GameInfo = {
    key: string,
    time: Date,
    value: string,
    gameMaster: boolean,
    invite: boolean
}

export class GameMgmt {
    readonly storageKeyPrefix: string = "game_";
    readonly hashMinLength = (24 + 32 + 16) * 4 / 3 + 1;

    createNewGameInfo(gameHash: string, invite: boolean): GameInfo {
        let time = Date.now();
        return {
            key: this.storageKeyPrefix + time,
            time: new Date(time),
            value: gameHash,
            gameMaster: gameHash.length > this.hashMinLength,
            invite: invite
        }
    }

    findOrStoreGameState(search: string): GameInfo {
        let ls = window.localStorage;
        for (let i = 0; i < ls.length; i++) {
            let key = ls.key(i);
            if (key === null) {
                continue;
            }
            if (key.startsWith(this.storageKeyPrefix)) {
                let value = ls.getItem(key);
                if (value == null) {
                    continue;
                }
                if (value === search) {
                    let time = parseInt(key.slice(this.storageKeyPrefix.length));
                    if (isFinite(time) && time > 0) {
                        return {
                            key: key,
                            time: new Date(time),
                            value: value,
                            gameMaster: value.length > this.hashMinLength,
                            invite: false
                        }
                    }
                }
            }
        }
        let time = Date.now();
        let key = this.storageKeyPrefix + time;
        ls.setItem(key, search);
        return {
            key: key,
            time: new Date(time),
            value: search,
            gameMaster: search.length > this.hashMinLength,
            invite: false
        };
    }

    loadGameList() : GameInfo[] {
        let ls = window.localStorage;
        let accessList: Array<GameInfo> = [];
        for (let i = 0; i < ls.length; i++) {
            let key = ls.key(i);
            if (key === null) {
                continue;
            }
            if (key.startsWith(this.storageKeyPrefix)) {
                let time = parseInt(key.slice(this.storageKeyPrefix.length));
                if (!isFinite(time) && time <= 0) {
                    continue;
                }
                let value = ls.getItem(key);
                if (value == null) {
                    continue;
                }
                accessList.push({
                    key: key,
                    time: new Date(time),
                    value: value,
                    gameMaster: value.length > this.hashMinLength,
                    invite: false
                })
            }
        }
        return accessList.sort((a, b) => {
            return a.time < b.time ? 1 : (a.time > b.time ? -1 : 0);
        })
    }
}