import React from "react";
import 'chart.js/auto';
import {Line} from 'react-chartjs-2';
import {Graphable} from "../Math";

type FormulaChartProps = {
    formula: Graphable
    label: string
    hours?: boolean
}

export class FormulaChart extends React.PureComponent<FormulaChartProps, any> {
    private steps: number = 144;

    render() {
        const dataPoints = this.props.formula.dataPoints(this.steps);
        return <Line height={150} width={"100%"} data={{
            labels: dataPoints.labels,
            datasets: [{
                label: this.props.label,
                data: dataPoints.data,
                borderColor: 'rgba(99, 99, 255, 1)',
                borderWidth: 1,
                showLine: false,
                xAxisID: "x",
                yAxisID: "y",
                spanGaps: true
            }]
        }} options={{
            responsive: true,
            maintainAspectRatio: false,
            scales: {
                y: {
                    beginAtZero: true,
                    ticks: {
                        format: {style: this.props.label==="Percentage"?"percent":"decimal"}
                    }
                },
                x: {
                    type: "linear",
                    ticks: {

                        format: this.props.hours === true?{
                            style: "unit",
                            unit: "hour"
                        }: undefined
                    }
                }
            },
            plugins: {
                legend: {
                    display: false
                }
            }
        }} />;
    }
}