import React from "react";
import {IonIcon, IonItem, IonLabel, IonMenuToggle} from "@ionic/react";
import {useLocation} from "react-router-dom";

type MenuItemProps = {
    url: string
    icon: string
    title: string
}

const MenuItem: React.FC<MenuItemProps> = (props) => {
    const location = useLocation();

    return (
        <IonMenuToggle autoHide={false}>
            <IonItem className={location.pathname === props.url ? 'selected' : ''} routerLink={props.url} lines="none" detail={false}>
                <IonIcon slot="start" icon={props.icon}/>
                <IonLabel>{props.title}</IonLabel>
            </IonItem>
        </IonMenuToggle>
    )
}

export default MenuItem;