import React from "react";
import {renderHeader, taskSorter} from "../components/Utils";
import {TaskListItem} from "../components/TaskListItem";
import {CompleteTask, StateMachine, Task} from "../StateMachine";
import {IonButton, IonContent, IonFab, IonFabButton, IonIcon, IonListHeader, IonPage} from "@ionic/react";
import {add} from "ionicons/icons";

type TaskListProps = {
    gameMaster: boolean
    points: number
    tasks: Map<string, Task>
    pushEvent(task: CompleteTask): void
    initialized: boolean
}

export class TaskList extends React.PureComponent<TaskListProps, any> {

    render() {
        return <IonPage>
            {renderHeader("Tasks", true, this.props.points)}

            <IonContent fullscreen >
                {renderHeader("Tasks", false, this.props.points)}
                {this.props.initialized ||
                    <IonButton routerLink={this.props.gameMaster?"/history/setPoints":undefined} expand={"block"} color={"danger"}>
                        Game not started
                    </IonButton>
                }
            <IonListHeader>Available tasks</IonListHeader>
            {
                Array.from(this.props.tasks.values())
                    .filter(task => !task.suggested && StateMachine.canCompleteTask(task, this.props.points).valid)
                    .sort(taskSorter)
                    .map(task =>
                        <TaskListItem key={task.id} task={task}
                                      canComplete={this.props.initialized}
                                      pushEvent={this.props.pushEvent}
                        />
                    )
            }
            <IonListHeader>Unavailable tasks</IonListHeader>
            {
                Array.from(this.props.tasks.values())
                    .filter(task => !task.suggested && !StateMachine.canCompleteTask(task, this.props.points).valid)
                    .sort(taskSorter)
                    .map(task =>
                        <TaskListItem key={task.id} task={task}
                                      canComplete={false}
                                      pushEvent={this.props.pushEvent}
                        />
                    )
            }
            <IonListHeader>Suggested tasks</IonListHeader>
            {
                Array.from(this.props.tasks.values())
                    .filter(task => task.suggested)
                    .sort(taskSorter)
                    .map(task =>
                        <TaskListItem key={task.id} task={task}
                                      canComplete={false}
                                      pushEvent={this.props.pushEvent}
                        />
                    )
            }
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton routerLink={"/tasks/add"}>
                    <IonIcon icon={add} />
                </IonFabButton>
            </IonFab>
            </IonContent>
        </IonPage>;
    }
}