import React from "react";
import {
    IonCard, IonCardContent,
    IonContent,
    IonPage
} from "@ionic/react";
import {renderHeader} from "../components/Utils";

export class PrivacyPage extends React.PureComponent<any, any> {

    render() {
        return <IonPage>
            {renderHeader("Privacy Policy", true)}

            <IonContent fullscreen>
                {renderHeader("Privacy Policy", false)}
                <IonCard>

                    <IonCardContent>
                        <p>
                            The following data will be stored in <a href="https://firebase.google.com/">Google Firebase</a> in plain:
                        </p>
                        <ul>
                            <li>User-Id (randomly assigned)</li>
                            <li>Timestamps</li>
                            <li>If a player is gamemaster</li>
                        </ul>
                        <p>
                            This application
                            uses <a href={"https://nacl.cr.yp.to/"} target={"_blank"} rel={"noreferrer"}>NaCl</a> to
                            encrypt sensitive data.
                            Each game has an unique encryption key, that is only accessible to the players of that game.
                            The following data will be stored encrypted in <a href="https://firebase.google.com/">Google Firebase</a>:
                        </p>
                        <ul>
                            <li>Player names</li>
                            <li>Events</li>
                            <li>Gear items and Tasks</li>
                        </ul>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    }
}