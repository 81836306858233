import React from "react";
import {GameAccess, UserMgmt} from "../UserMgmt";
import {randomBytes} from "tweetnacl-ts/es/random";
import {encodeBase64} from "tweetnacl-ts/es/client/convert";
import {renderHeader} from "../components/Utils";
import {IonCard, IonCardContent, IonContent, IonInput, IonPage} from "@ionic/react";

type InvitePlayerProps = {
    gameAccess?: GameAccess
}

type InvitePlayerState = { hash: undefined } | {
    hash: string
    password: string
}

export class InvitePlayer extends React.PureComponent<InvitePlayerProps, InvitePlayerState> {
    constructor(props: InvitePlayerProps) {
        super(props);
        this.state = {
            hash: undefined
        }
    }

    componentDidMount() {
        this.calculateHash();
    }

    componentDidUpdate(prevProps: Readonly<InvitePlayerProps>, prevState: Readonly<InvitePlayerState>, snapshot?: any) {
        if (prevProps.gameAccess !== this.props.gameAccess) {
            this.calculateHash();
        }
    }

    calculateHash() {
        if (this.props.gameAccess === undefined) {
            return;
        }
        const password = encodeBase64(randomBytes(9));
        UserMgmt.createHash({
            gameId: this.props.gameAccess.gameId,
            gameId64: this.props.gameAccess.gameId64,
            gameMaster: false,
            gameSecretKey: this.props.gameAccess.gameSecretKey,
            userId: ""
        }, password).then(e => {
            this.setState({
                hash: "I" + e.slice(1),
                password: password
            })
        });
    }

    render() {

        let url = this.state.hash === undefined ? "Calculating hash..." : window.location.protocol + "//" + window.location.host + "/game#" + this.state.hash;
        return <IonPage>
            {renderHeader("Invite player", true)}

            <IonContent fullscreen>
                {renderHeader("Invite player", false)}
                <IonCard>

                    <IonCardContent>
                        Send the following link to the player:
                        <blockquote>
                            <code>
                                <a href={url}>{url}</a>
                            </code>
                        </blockquote>
                        Invite password (click to copy to clipboard):
                        <blockquote>
                            <IonInput value={this.state.hash !== undefined ? this.state.password : ""}
                                   onFocus={() => {
                                       if (this.state.hash !== undefined) {
                                           window.navigator.clipboard.writeText(this.state.password);
                                       }
                                   }}
                            />
                        </blockquote>
                    </IonCardContent>
                </IonCard>
            </IonContent>
        </IonPage>
    }
}