import React from "react";
import {SetPoints} from "../StateMachine";
import {parseIntOrDefault, renderHeader} from "../components/Utils";
import {
    IonButton,
    IonCard,
    IonCardContent,
    IonContent, IonInput,
    IonItem, IonList,
    IonListHeader,
    IonPage,
    IonToast
} from "@ionic/react";
import {History} from "history";

type DesiredGearCountProps = {
    isGameMaster: boolean
    pushEvent(event: SetPoints): void
    currentPoints?: number
    history: History
}

type DesiredGearCountState = {
    points: number
}

export class SetPointsPage extends React.PureComponent<DesiredGearCountProps, DesiredGearCountState> {

    constructor(props: DesiredGearCountProps) {
        super(props);
        this.state = this.getCurrentState()
    }

    getCurrentState(): DesiredGearCountState {
        return {
            points: this.props.currentPoints === undefined? 0 : Math.round(this.props.currentPoints*100)/100
        }
    }

    componentDidUpdate(prevProps: Readonly<DesiredGearCountProps>, prevState: Readonly<DesiredGearCountState>, snapshot?: any) {
        if (prevProps.currentPoints !== this.props.currentPoints) {
            this.setState(this.getCurrentState())
        }
    }

    closePage = () => {
        this.props.history.push("/history/")
    }

    save = () => {
        try {
            this.props.pushEvent({
                type: "setPoints",
                points: this.state.points
            });
            this.closePage()
        } catch (e) {
            console.log(e);
        }
    }

    render() {
        const canModify = this.props.isGameMaster;
        let error: string | undefined;
        if (this.state.points === undefined || !isFinite(this.state.points)) {
            error = "Point score must be set to a valid number";
        }
        return <IonPage>
            {renderHeader("Set point score", true, undefined, this.closePage)}

            <IonContent fullscreen>
                {renderHeader("Set point score", false, undefined, this.closePage)}
                <IonCard>
                    <IonCardContent>
                        <p> You can change the current point score at any time.
                            You have to provide an initial point score, to start the game.</p>
                    </IonCardContent>
                </IonCard>
                <IonList>
                    <IonListHeader>
                        Current Point score: {this.props.currentPoints === undefined? "Not set":Math.round(this.props.currentPoints*100)/100}
                    </IonListHeader>
                    {canModify &&
                        <IonItem>
                            <IonInput type="number" placeholder="Set point score"
                                      value={this.state.points}
                                      onIonChange={(event) => typeof event.detail.value === "string" &&
                                          event.detail.value !== "" &&
                                          this.setState({
                                              points: parseIntOrDefault(event.detail.value, 0)
                                          })}
                            />
                        </IonItem>
                    }

                </IonList>


                {canModify &&
                    <IonButton expand="block" onClick={this.save}>Set</IonButton>
                }

                <IonToast key="error" isOpen={error !== undefined} message={error} duration={5000}/>
            </IonContent>
        </IonPage>
    }
}