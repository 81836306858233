import {UserId} from "./Sync";
import {DurationFormula, DurationFormulaCalc, Formula, FormulaObject} from "./Math";

export type GameEvent =
    SetPoints |
    DesiredGearCountChange |
    DailyDecreaseChange |
    AddOrModifyGear |
    SuggestGear |
    AddOrModifyTask |
    SuggestTask |
    EquipGear |
    UnequipGear |
    RemoveGear |
    CompleteTask |
    RemoveTask

export type ValidationFailure = {
    valid: false
    message: string
}

export type ValidationSuccess = {
    valid: true
    pointsResult?: PointsResult
}

export type BaseEvent = {
    timestamp?: number,
    userId?: string,
    eventId?: string,
    validation?: ValidationResult,
    removed?: boolean
}
export type SetPoints = BaseEvent & {
    readonly type: "setPoints"
    readonly points: number
}
export type DesiredGearCountChange = BaseEvent & {
    readonly type: "desiredGearCountChange"
    readonly count: Formula
}
export type DailyDecreaseChange = BaseEvent & {
    readonly type: "dailyDecreaseChange"
    readonly dailyDecrease: number
}
export type BaseGearEvent = BaseEvent & {
    readonly gear: EventGear
}
export type AddOrModifyGear = BaseGearEvent & {
    readonly type: "addOrModifyGear"
}
export type SuggestGear = BaseGearEvent & {
    readonly type: "suggestGear"
}
export type BaseTaskEvent = BaseEvent & {
    readonly task: EventTask
}
export type AddOrModifyTask = BaseTaskEvent & {
    readonly type: "addOrModifyTask"
}
export type SuggestTask = BaseTaskEvent & {
    readonly type: "suggestTask"
}
export type RemoveGear = BaseGearEvent & {
    readonly type: "removeGear"
}
export type EquipGear = BaseGearEvent & {
    readonly type: "equipGear"
}
export type UnequipGear = BaseGearEvent & {
    readonly type: "unequipGear"
}
export type RemoveTask = BaseTaskEvent & {
    readonly type: "removeTask"
}
export type CompleteTask = BaseTaskEvent & {
    readonly type: "completeTask"
}

export type Gear = EquippedGear | UnequippedGear | SuggestedGear

type BaseGear = {
    readonly id: string
    readonly title: string
}

export type EventGear = BaseGear & {
    readonly desiredDuration: DurationFormula
}

export type UnequippedGear = BaseGear & {
    readonly desiredDuration: DurationFormulaCalc
    readonly equipped: false
    readonly suggested: false
    readonly lastWorn?: Date
}

export type SuggestedGear = BaseGear & {
    readonly desiredDuration: DurationFormulaCalc
    readonly equipped: false
    readonly suggested: true
}

export type EquippedGear = BaseGear & {
    readonly desiredDuration: DurationFormulaCalc
    readonly equipped: true
    readonly suggested: false
    readonly wornSince: Date
    readonly timestampCounts: Array<TimestampCount>
}

export type EventTask = {
    readonly id: string;
    readonly title: string;
    readonly description: string;
    readonly points: number;
    readonly requirement: "no" | "lt" | "gt" | "le" | "ge";
    readonly requirementPoints?: number;
    readonly unlimitedRepeats: boolean;
    readonly availableRepeats?: number;
    readonly active: boolean;
}

export type Task = EventTask & {
    readonly suggested: boolean;
}

export type GameState = {
    initialized: boolean,
    points: number,
    timestamp: number,
    desiredGearCount: FormulaObject,
    dailyDecrease: number,
    gear: Map<string, Gear>,
    tasks: Map<string, Task>
}

type BaseData = ValidationSuccess & {
    user: UserId,
    timestamp: number
}

type TimestampCount = {
    timestamp: number,
    count: number
}

export type PointsResult = {
    points: number,
    hours: number
}

export type ValidationResult = ValidationSuccess | ValidationFailure;

function success(pointsResult?: PointsResult): ValidationSuccess {
    return {valid: true, pointsResult: pointsResult}
}

function fail(message: string): ValidationFailure {
    return {valid: false, message: message}
}

export class StateMachine {

    static uninitalizedState(): Readonly<GameState>{
        return {
            initialized: false,
            points: 0,
            timestamp: Date.now(),
            desiredGearCount: FormulaObject.create({desiredValue: 1, baseValue: 0.5, maxValue: 1, difficulty: 0.5}),
            dailyDecrease: 0,
            gear: new Map<string, Gear>(),
            tasks: new Map<string, Task>()
        }
    };

    private currentState: GameState = {...StateMachine.uninitalizedState()};
    userMap: Map<string, UserId> = new Map<string, UserId>();

    getCurrentState(): Readonly<GameState> {
        return this.currentState;
    }

    private baseCheck(event: BaseEvent, requireGameMaster: boolean, initialized: boolean): BaseData | ValidationFailure {
        if (initialized && !this.currentState.initialized) {
            return fail("Game in wrong state");
        }
        if (event.timestamp === undefined || event.timestamp < Date.UTC(2022, 1) ||
            event.timestamp > Date.now() + 5000) {
            return fail("Timestamp invalid");
        }
        if (event.userId === undefined) {
            return fail("UserId not set");
        }
        let user = this.userMap.get(event.userId);
        if (user === undefined) {
            return fail("User not found");
        }
        if (requireGameMaster && !user.gameMaster) {
            return fail("Game Master Event send by player");
        }
        if (event.eventId === undefined || event.eventId.length !== 25) {
            return fail("Event id not set");
        }
        return {valid: true, user: user, timestamp: event.timestamp};
    }

    setPoints(event: SetPoints): ValidationResult {
        let baseData = this.baseCheck(event, true, false);
        if (!baseData.valid) {
            return baseData;
        }
        if (!isFinite(event.points)) {
            return fail("Points not a finite number");
        }
        this.currentState.initialized = true;
        this.currentState.points = event.points;
        this.currentState.timestamp = baseData.timestamp;
        return success();
    }

    desiredGearCountChange(event: DesiredGearCountChange): ValidationResult {
        let baseData = this.baseCheck(event, true, false);
        if (!baseData.valid) {
            return baseData;
        }

        try {
            let count = FormulaObject.create(event.count);
            if (count.maxValue !== 1) {
                return fail("Max value is not 1");
            }
            this.currentState.desiredGearCount = count;
            return success();
        } catch (e: any) {
            console.log(e);
            return fail(e.message);
        }
    }

    dailyDecreaseChange(event: DailyDecreaseChange): ValidationResult {
        let baseData = this.baseCheck(event, true, false);
        if (!baseData.valid) {
            return baseData;
        }

        if (!isFinite(event.dailyDecrease) || event.dailyDecrease < 0) {
            return fail("Daily decrease should finite and positive");
        }

        this.updatePoints(baseData.timestamp)
        this.currentState.dailyDecrease = event.dailyDecrease;
        return success();
    }

    addOrModifyGear(event: AddOrModifyGear): ValidationResult {
        let baseData = this.baseCheck(event, true, false);
        if (!baseData.valid) {
            return baseData;
        }

        let gear: UnequippedGear;
        try {
            gear = {
                ...event.gear, desiredDuration: DurationFormulaCalc.create(event.gear.desiredDuration),
                equipped: false, suggested: false
            };
        } catch (e: any) {
            console.log(e);
            return fail(e.message);
        }
        if (gear.title.length === 0 || gear.id.length !== 25) {
            return fail("Title or id have the wrong length");
        }
        if (gear.desiredDuration.duration > 168) {
            return fail("Desired duration too long");
        }
        let oldGear = this.currentState.gear.get(gear.id);
        if (oldGear !== undefined && oldGear.equipped) {
            let equippedGear: EquippedGear = {
                ...gear,
                equipped: true,
                wornSince: oldGear.wornSince,
                timestampCounts: oldGear.timestampCounts
            }
            this.currentState.gear.set(gear.id, equippedGear);
        } else {
            this.currentState.gear.set(gear.id, gear);
        }

        return success();
    }

    suggestGear(event: SuggestGear): ValidationResult {
        let baseData = this.baseCheck(event, false, false);
        if (!baseData.valid) {
            return baseData;
        }

        let gear: SuggestedGear;
        try {
            gear = {
                ...event.gear, desiredDuration: DurationFormulaCalc.create(event.gear.desiredDuration),
                equipped: false, suggested: true
            };
        } catch (e: any) {
            console.log(e);
            return fail(e.message);
        }
        if (gear.title.length === 0 || gear.id.length !== 25) {
            return fail("Title or id have the wrong length");
        }
        if (gear.desiredDuration.duration > 168) {
            return fail("Desired duration too long");
        }
        if (this.currentState.gear.has(gear.id)) {
            return fail("Can't suggest existing gear (same id)");
        }
        this.currentState.gear.set(gear.id, gear);
        return success();
    }

    equipGear(event: EquipGear): ValidationResult {
        let baseData = this.baseCheck(event, false, true);
        if (!baseData.valid) {
            return baseData;
        }

        let gear = this.currentState.gear.get(event.gear.id);
        if (gear === undefined || gear.suggested || gear.equipped) {
            return fail("Can't find gear");
        }


        let equippedGear: EquippedGear = {
            ...gear,
            wornSince: new Date(baseData.timestamp), timestampCounts: [],
            equipped: true
        };
        this.currentState.gear.set(gear.id, equippedGear);
        this.updateTimestampCounts(baseData);
        return success();
    }

    unequipGear(event: UnequipGear): ValidationResult {
        let baseData = this.baseCheck(event, false, true);
        if (!baseData.valid) {
            return baseData;
        }

        let gear = this.currentState.gear.get(event.gear.id);
        if (gear === undefined || !gear.equipped) {
            return fail("Can't find gear");
        }

        if (gear.timestampCounts === undefined || gear.wornSince === undefined) {
            return fail("Invalid gear state");
        }

        let result = StateMachine.calculateGearPoints(gear, baseData.timestamp, this.currentState.desiredGearCount);
        if (result === undefined) {
            return fail("Total time less than 1 ms");
        }
        let unequippedGear: UnequippedGear = {
            ...gear,
            equipped: false,
            lastWorn: new Date(baseData.timestamp)
        };
        this.currentState.points += result.points;
        this.currentState.gear.set(gear.id, unequippedGear);
        this.updateTimestampCounts(baseData);
        return success(result);
    }

    static calculateGearPoints(gear: EquippedGear, timestampEnd: number, desiredGearCount: FormulaObject): PointsResult | undefined {
        let lastTimestamp = timestampEnd;
        let count = 0;
        for (const tc of Array.from(gear.timestampCounts).reverse()) {
            count += tc.count * (lastTimestamp - tc.timestamp)
            lastTimestamp = tc.timestamp;
        }
        let totalTime = timestampEnd - gear.wornSince.getTime();
        if (totalTime < 1) {
            return undefined;
        }
        let avg = count / totalTime;
        let desiredGearCountRatio = desiredGearCount.calculate(avg);
        let hours = totalTime / (60 * 60 * 1000);
        let desiredDurationRatio = gear.desiredDuration.calculate(hours);
        return {points: desiredDurationRatio * desiredGearCountRatio, hours: hours};
    }

    addOrModifyTask(event: AddOrModifyTask): ValidationResult {
        let baseData = this.baseCheck(event, true, false);
        if (!baseData.valid) {
            return baseData;
        }

        let task: EventTask = event.task;
        let check = StateMachine.checkTask(task);
        if (!check.valid) {
            return check;
        }
        this.currentState.tasks.set(task.id, {...task, suggested: false});
        return success();
    }

    suggestTask(event: SuggestTask): ValidationResult {
        let baseData = this.baseCheck(event, false, false);
        if (!baseData.valid) {
            return baseData;
        }

        let task: EventTask = event.task;
        let check = StateMachine.checkTask(task);
        if (!check.valid) {
            return check;
        }
        if (this.currentState.tasks.has(task.id)) {
            return fail("Duplicate id");
        }
        this.currentState.tasks.set(task.id, {...task, suggested: true});
        return success();
    }

    completeTask(event: CompleteTask): ValidationResult {
        let baseData = this.baseCheck(event, false, true);
        if (!baseData.valid) {
            return baseData;
        }

        let task = this.currentState.tasks.get(event.task.id);
        if (task === undefined) {
            return fail("Task not found");
        }

        let points = this.calculatePoints(baseData.timestamp);
        let result = StateMachine.canCompleteTask(task, points);
        if (!result.valid) {
            return result;
        }
        this.currentState.points += task.points;
        if (!task.unlimitedRepeats && task.availableRepeats !== undefined) {
            this.currentState.tasks.set(task.id, {...task, availableRepeats: task.availableRepeats - 1});
        }
        return success();
    }

    removeGear(event: RemoveGear): ValidationResult {
        let baseData = this.baseCheck(event, true, false);
        if (!baseData.valid) {
            return baseData;
        }

        if (!this.currentState.gear.has(event.gear.id)) {
            return fail("Gear not found")
        }
        let gear = this.currentState.gear.get(event.gear.id);
        if (gear && gear.equipped) {
            return fail("Gear is equipped")
        }
        this.currentState.gear.delete(event.gear.id);
        return success()
    }

    removeTask(event: RemoveTask): ValidationResult {
        let baseData = this.baseCheck(event, true, false);
        if (!baseData.valid) {
            return baseData;
        }

        if (!this.currentState.tasks.has(event.task.id)) {
            return fail("Task not found")
        }
        this.currentState.tasks.delete(event.task.id);

        return success()
    }

    static canCompleteTask(task: Task, points: number): ValidationResult {
        if (!task.active) {
            return fail("Task not active")
        }
        if (!task.unlimitedRepeats && (task.availableRepeats === undefined || task.availableRepeats < 1)) {
            return fail("No repeats left")
        }
        if (task.requirement === "no") {
            return success()
        }
        if (task.requirementPoints === undefined) {
            return fail("Requirement points not set")
        }
        if (task.requirement === "lt" && !(points < task.requirementPoints)) {
            return fail("Requirement not met: " + points + " < " + task.requirementPoints)
        }
        if (task.requirement === "le" && !(points <= task.requirementPoints)) {
            return fail("Requirement not met: " + points + " <= " + task.requirementPoints)
        }
        if (task.requirement === "gt" && !(points > task.requirementPoints)) {
            return fail("Requirement not met: " + points + " > " + task.requirementPoints)
        }
        if (task.requirement === "ge" && !(points >= task.requirementPoints)) {
            return fail("Requirement not met: " + points + " >= " + task.requirementPoints)
        }
        return success();
    }

    static checkTask(task: EventTask): ValidationResult {
        if (task.title.length === 0 || task.id.length !== 25) {
            return fail("Title or id length is wrong");
        }
        if (task.description.length > 512) {
            return fail("Description too long");
        }
        if (!task.unlimitedRepeats &&
            (task.availableRepeats === undefined ||
                !isFinite(task.availableRepeats) || task.availableRepeats < 0)) {
            return fail("Available Reapeats less than 0");
        }
        if (!isFinite(task.points)) {
            return fail("Points is not finite");
        }
        if (task.requirement !== "no" && (
            task.requirementPoints === undefined || !isFinite(task.requirementPoints))) {
            return fail("Points requirement is not finite");
        }
        return success();
    }

    private updateTimestampCounts(baseData: BaseData) {
        const count: number = Array.from(this.currentState.gear.values()).filter(e => e.equipped).length;
        const timestamp: number = baseData.timestamp;
        this.currentState.gear.forEach((value, key, map) => {
                if (value.equipped) {
                    map.set(key, {
                        ...value, timestampCounts:
                            value.timestampCounts === undefined ?
                                [{timestamp: timestamp, count: count}] :
                                [...value.timestampCounts, {timestamp: timestamp, count: count}]
                    })
                }
            }
        );
    }

    calculatePoints(timestamp: number): number {
        let timeDiff = (timestamp - this.currentState.timestamp) / (24 * 60 * 60 * 1000);
        return this.currentState.points - this.currentState.dailyDecrease * timeDiff
    }

    updatePoints(timestamp: number) {
        let points = this.calculatePoints(timestamp);
        this.currentState.timestamp = timestamp;
        this.currentState.points = points;
    }

    handleNextEvent(event: GameEvent): ValidationResult {
        if (event.removed === true) {
            return success();
        }
        switch (event.type) {
            case "setPoints":
                return this.setPoints(event);
            case "dailyDecreaseChange":
                return this.dailyDecreaseChange(event);
            case "desiredGearCountChange":
                return this.desiredGearCountChange(event);
            case "addOrModifyGear":
                return this.addOrModifyGear(event);
            case "suggestGear":
                return this.suggestGear(event);
            case "addOrModifyTask":
                return this.addOrModifyTask(event);
            case "suggestTask":
                return this.suggestTask(event);
            case "removeGear":
                return this.removeGear(event);
            case "equipGear":
                return this.equipGear(event);
            case "unequipGear":
                return this.unequipGear(event);
            case "removeTask":
                return this.removeTask(event);
            case "completeTask":
                return this.completeTask(event);
        }
    }


}